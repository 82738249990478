export default {
  global: {
    welcome: "હાય, ફરી સ્વાગત છે",
    admin: "એડમિન",
    dashboard: "ડેશબોર્ડ",
    events: "ઇવેન્ટ્સ",
    rsvp: "આરએસવીપી",
    connections: "કનેક્શન્સ",
    groups: "જૂથો",
    invitation: "આમંત્રણ",
    eventOperations: "ઇવેન્ટ ઓપરેશન્સ",
    master: "માસ્ટર",
    postEvent: "ઘટના પોસ્ટ કરો",
    reports: "અહેવાલો",
    attendees: "હાજરી આપનાર",
    tickets: "ટિકિટ",
    checkin: "ચેકઇન",
    users: "વપરાશકર્તાઓ",
    eventTypes: "ઇવેન્ટના પ્રકારો",
    userTypes: "વપરાશકર્તા પ્રકાર",
    roles: "ભૂમિકાઓ",
    memberType: "સભ્ય પ્રકારો",
    emailTemplate: "ઈમેઈલ ટેમ્પલેટ",
    coupons: "કૂપન્સ",
    company: "કંપની",
    plans: "યોજનાઓ",
    home: "ઘર",
    profile: "પ્રોફાઇલ",
    logout: "લૉગ આઉટ",
    action: "ક્રિયા",
    edit: "સંપાદિત કરો",
    add: "ઉમેરો",
    delete: "કાઢી નાખો",
    save: "સાચવો",
    update: "અપડેટ કરો",
    view: "જુઓ",
    search: "શોધો",
    cancel: "રદ કરો",
    preview: "પૂર્વાવલોકન",
    deleteMsg: "શું તમે ખરેખર કાઢી નાખવા માંગો છો?",
    cancelMsg: "શું તમે ખરેખર રદ કરવા માંગો છો?",
    name: "નામ",
    firstName: "પ્રથમ નામ",
    lastName: "છેલ્લું નામ",
    email: "ઈમેલ",
    phoneNumber: "ફોન નંબર",
    phone: "ફોન",
    status: "સ્થિતિ",
    submit: "સબમિટ કરો",
    createdOn: "ના રોજ બનાવ્યું",
    active: "સક્રિય",
    inactive: "નિષ્ક્રિય",
    yes: "હા",
    no: "ના",
    title: "શીર્ષક",
  },
  event: {
    selectEventMode: "ઇવેન્ટ મોડ પસંદ કરો",
    offline: "ઑફલાઇન",
    online: "ઓનલાઈન",
    hybrid: "વર્ણસંકર",
    upcoming: "આગામી",
    ongoing: "ચાલુ છે",
    completed: "પૂર્ણ થયું",
    createEvent: "ઇવેન્ટ બનાવો",
    noEventsFound: "કોઈ ઇવેન્ટ્સ મળી નથી",
    duplicate: "ડુપ્લિકેટ",
    addEvent: "ઇવેન્ટ ઉમેરો",
    selectEventType: "ઇવેન્ટનો પ્રકાર પસંદ કરો",
    eventTitle: "ઇવેન્ટ શીર્ષક",
    startDate: "પ્રારંભ તારીખ",
    endDate: "અંતિમ તારીખ",
    eventDescription: "ઇવેન્ટ વર્ણન",
    writeSomethingAwesome: "કંઈક અદ્ભુત લખો...",
    eventInstructions: "ઇવેન્ટ સૂચનાઓ",
    eventCapacity: "ઇવેન્ટ ક્ષમતા",
    paid: "ચૂકવેલ?",
    eventPrice: "ઇવેન્ટ કિંમત",
    addLocation: "સ્થાન ઉમેરો",
    uploadCoverImage: "કવર છબી અપલોડ કરો",
    uploadFloorPlan: "ફ્લોર પ્લાન અપલોડ કરો",
    addSubEvent: "સબ ઇવેન્ટ ઉમેરો",
    subEvents: "પેટા ઇવેન્ટ્સ",
    location: "સ્થાન",
    eventLocation: "ઇવેન્ટ સ્થાન",
    locationRequiredMsg: "કૃપા કરીને સ્થાન દાખલ કરો",
    multipleLocations: "બહુવિધ સ્થાનો",
    crewMembers: "ક્રૂ સભ્યો",
    selectMemberType: "સભ્ય પ્રકાર પસંદ કરો",
    addMember: "સભ્ય ઉમેરો",
    updateOngoingEventMsg: "તમે ચાલુ અથવા ભૂતકાળની ઇવેન્ટ્સ સંશોધિત કરી શકતા નથી.",
    updateRSVPEventMsg:
      "ઇવેન્ટમાં ફેરફાર કરતા પહેલા કૃપા કરીને આરએસવીપી અને અન્ય સંબંધિત ડેટા કાઢી નાખો.",
    deleteOngoingEventMsg: "તમે ચાલુ અથવા ભૂતકાળની ઇવેન્ટ્સ કાઢી શકતા નથી.",
    deleteRSVPEventMsg:
      "ઇવેન્ટ ડિલીટ કરતા પહેલા કૃપા કરીને આરએસવીપી અને અન્ય સંબંધિત ડેટા ડિલીટ કરો.",
    editEvent: "ઇવેન્ટ સંપાદિત કરો",
    free: "મફત",
    about: "વિશે",
    description: "વર્ણન",
    noDescriptionAdded: "કોઈ વર્ણન ઉમેર્યું નથી",
    instructions: "સૂચનાઓ",
    noInstructionsAdded: "કોઈ સૂચનાઓ ઉમેરવામાં આવી નથી",
    startDateTime: "પ્રારંભ તારીખ/સમય",
    endDateTime: "સમાપ્તિ તારીખ/સમય",
    eventMode: "ઇવેન્ટ મોડ",
    selectEventTitle: "ઇવેન્ટ શીર્ષક પસંદ કરો",
    event: "ઇવેન્ટ",
    importEvent: "ઇવેન્ટ આયાત કરો",
    importRSVP: "આરએસવીપી આયાત કરો",
    importEventWithRSVP: "આરએસવીપી સાથે ઇવેન્ટ બનાવો",
    singleEvent: "સિંગલ ઇવેન્ટ",
    multipleEvents: "બહુવિધ ઇવેન્ટ્સ",
    viewEvent: "ઇવેન્ટ જુઓ",
    saveAsDraft: "ડ્રાફ્ટ તરીકે સાચવો",
    noLocations: "કોઈ સ્થાનો નથી",
    noLink: "કોઈ લિંક નથી",
    noLinkProvidedbyContact: "સંપર્ક દ્વારા કોઈ લિંક આપવામાં આવી નથી",
    addOns: "ઍડ-ઑન્સ",
    eventFeatures: "ઇવેન્ટ સુવિધાઓ",
    feedbackForm: "પ્રતિસાદ ફોર્મ",
    feedbackFormDescription: "પ્રતિભાગીઓ પોસ્ટ ઇવેન્ટ દ્વારા ભરવા માટે કસ્ટમ ફીડબેક ફોર્મ્સ બનાવો",
    agenda: "કાર્યસૂચિ",
    agendaDescription: "ઇવેન્ટ એજન્ડા અને તેમની ટીમના સભ્યોને વ્યાખ્યાયિત કરો",
    faqs: "FAQs",
    faqDescription: "ઇવેન્ટ દર્શકો માટે FAQ વિભાગ બનાવો",
    customRSVP: "કસ્ટમ આરએસવીપી",
    customRSVPDescription: "ઇવેન્ટ માટે આરએસવીપી ફોર્મ્સ વ્યાખ્યાયિત કરો",
    selectFromExisting: "અસ્તિત્વમાં છે તેમાંથી પસંદ કરો",
    createYourOwn: "તમારી પોતાની બનાવો",
    selectFeedbackForm: "પ્રતિસાદ ફોર્મ પસંદ કરો",
    selectAgenda: "કાર્યસૂચિ પસંદ કરો",
    selectFAQ: "FAQ પસંદ કરો",
    selectRSVPForm: "આરએસવીપી ફોર્મ પસંદ કરો",
    requestFeedback: "પ્રતિસાદની વિનંતી કરો",
    userResponses: "વપરાશકર્તા પ્રતિસાદો",
    noMembersAdded: "કોઈ સભ્યો ઉમેર્યા નથી",
    viewingDraftEvent: "તમે ડ્રાફ્ટ ઇવેન્ટ જોઈ રહ્યાં છો.",
    reoccurringFromPreviousEvent: "પાછલી ઇવેન્ટમાંથી પુનરાવર્તિત થાય છે?",
  },
  rsvp: {
    requiredFieldAtleastone: "કૃપા કરીને ઓછામાં ઓછો એક વિકલ્પ પસંદ કરો",
    requiredField: "આવશ્યક ક્ષેત્ર",
    published: "પ્રકાશિત",
    draft: "ડ્રાફ્ટ",
    cancelled: "રદ કરેલ",
    formDesign: "ફોર્મ ડિઝાઇન",
    details: "વિગતો",
    createdBy: "દ્વારા બનાવવામાં",
    copyRSVP: "આરએસવીપી કૉપિ કરો",
    addRSVP: "આરએસવીપી ઉમેરો",
    editRSVP: "આરએસવીપી સંપાદિત કરો",
    viewRSVP: "આરએસવીપી જુઓ",
    generalSettings: "સામાન્ય સુયોજનો",
    designForm: "ડિઝાઇન ફોર્મ",
    configureReminders: "રીમાઇન્ડર્સ ગોઠવો",
    viewPublish: "જુઓ અને પ્રકાશિત કરો",
    rsvpByDate: "તારીખ દ્વારા આરએસવીપી",
    rsvpByTime: "સમય દ્વારા આરએસવીપી",
    allowGuestsToDo: "મહેમાનોને કરવાની મંજૂરી આપો",
    individualRSVP: "વ્યક્તિગત આરએસવીપી",
    setLimitForPlusOnes: "પ્લસ-વન માટે મર્યાદા સેટ કરો",
    mentionPlusOne: "શું પ્રાથમિક આમંત્રિત વ્યક્તિએ પ્લસ-વનના નામનો ઉલ્લેખ કરવાની જરૂર છે?",
    emailAddressSupport: "ઇમેઇલ સરનામું (સપોર્ટ)",
    contactNoSupport: "સંપર્ક નંબર (સપોર્ટ)",
    firstReminderMsg:
      "પ્રાથમિક પ્રતિભાગી માટે તેમના પ્લસ-ઓનનાં નામ સબમિટ કરવા માટે પ્રથમ રીમાઇન્ડર ઇમેઇલ સેટ કરો",
    secondReminderMsg:
      "પ્રાથમિક પ્રતિભાગી તેમના પ્લસ-ઓનનાં નામ સબમિટ કરવા માટે બીજા/છેલ્લી રીમાઇન્ડર ઇમેઇલ ડેટા સેટ કરો",
    firstReminderInfo: `આ ઈમેલ પ્રાથમિક <br /> પ્રતિભાગીને તેમના પ્લસ-ઓનનું નામ <br /> સબમિટ કરવા માટે <br /> 1લા રીમાઇન્ડર તરીકે મોકલવામાં આવશે.`,
    firstReminderDate: "પ્રાથમિક હાજરીની માહિતી માટે પ્રથમ રીમાઇન્ડર તારીખ",
    secondReminderDate: "પ્રાથમિક હાજરીની માહિતી માટે બીજી રીમાઇન્ડર તારીખ",
    prev: "પૂર્વ",
    next: "આગળ",
    rsvpFormDesign: "આરએસવીપી ફોર્મ ડિઝાઇન",
    basic: "પાયાની",
    custom: "કસ્ટમ",
    basicFormFields: "મૂળભૂત ફોર્મ ક્ષેત્રો",
    customFormFields: "કસ્ટમ ફોર્મ ફીલ્ડ્સ",
    selectCustomTemplate: "કસ્ટમ ફોર્મ ટેમ્પલેટ પસંદ કરો",
    selectTemplate: "ટેમ્પલેટ પસંદ કરો",
    pleaseLetUsKnow: "કૃપા કરીને અમને જણાવો કે તમે તેને બનાવી શકશો.",
    yesIllBeThere: "હા, હું ત્યાં આવીશ",
    noIcantMakeIt: "ના, હું કરી શકતો નથી",
    useBasicFormMsg: "હું મારી ઇવેન્ટ માટે મૂળભૂત ફોર્મનો ઉપયોગ કરવા માંગુ છું",
    useForm: "ફોર્મનો ઉપયોગ કરો",
    typeAQuestion: "એક પ્રશ્ન લખો",
    selectFieldType: "ક્ષેત્ર પ્રકાર પસંદ કરો",
    phone: "ફોન",
    textbox: "ટેક્સ્ટબોક્સ",
    textarea: "ટેક્સટેરિયા",
    checkbox: "ચેકબોક્સ",
    dropdown: "ડ્રોપડાઉન",
    radioButton: "રેડીયો બટન",
    addFormElement: "ફોર્મ એલિમેન્ટ ઉમેરો",
    useCustomFormMsg: "હું મારી ઇવેન્ટ માટે કસ્ટમ ફોર્મનો ઉપયોગ કરવા માંગુ છું",
    designEmailTemplates: "ડિફૉલ્ટ અથવા રિકરિંગ ઇમેઇલ નમૂનાઓ ડિઝાઇન કરો",
    default: "ડિફૉલ્ટ",
    reminder: "રીમાઇન્ડર",
    messageWhen: "સંદેશ ક્યારે",
    rsvpIsYes: "આરએસવીપી હા છે",
    rsvpIsNo: "આરએસવીપી નંબર છે",
    reminderDate1st: "હાજરીની વિગતો માટે 1લી રીમાઇન્ડરની તારીખ નજીક આવી ગઈ છે",
    reminderDate2nd: "હાજરીની વિગતો માટે 2જી રીમાઇન્ડરની તારીખ નજીક આવી ગઈ છે",
    registrationClosed: "નોંધણી બંધ કરવાની છે",
    eventDateApproached: "ઘટનાની તારીખ નજીક આવી છે",
    subject: "વિષય",
    typeAMessage: "એક સંદેશ લખો",
    attachPaymentReceipt: "ચુકવણીની રસીદ જોડો",
    sendLinkToNamePlusOnes: "પ્લસ-વનને નામ આપવા માટે લિંક મોકલો",
    previewToRsvp: "આરએસવીપીનું પૂર્વાવલોકન",
    contactInfo: "સંપર્ક માહિતી",
    reservation: "આરક્ષણ",
    publishRSVPPage: "આરએસવીપી પૃષ્ઠ પ્રકાશિત કરો",
    rsvpLinkCopied: "તમારી આરએસવીપી લિંક કૉપિ કરવામાં આવી છે.",
    thankYou: "આભાર!",
    alreadySubmitted: "તમે પહેલેથી જ આરએસવીપી ફોર્મ સબમિટ કર્યું છે.",
    reachUsForAssistance: "તમે કોઈપણ સહાય માટે અમારો સંપર્ક કરી શકો છો:",
    formSubmitted: "તમારું આરએસવીપી ફોર્મ સફળતાપૂર્વક સબમિટ કરવામાં આવ્યું છે.",
    eventAtFullCapacity: "આ ઇવેન્ટ સંપૂર્ણ ક્ષમતા પર છે.",
    thankYouForYourInterest: "તમારા રસ માટે આભાર!",
    rsPerPerson: "રૂ/વ્યક્તિ",
    total: "કુલ:",
    payWithCard: "કાર્ડ વડે ચૂકવણી કરો",
    payNow: "હવે પૈસા આપો",
    reasonToRefuse: "ના પાડવાનું કારણ",
    additionalComments: "વધારાની ટિપ્પણીઓ / તમારા કારણનો ઉલ્લેખ કરો",
    dateNotSuitable: "તારીખ યોગ્ય નથી",
    timeNotSuitable: "સમય અનુકુળ નથી",
    priceTooHigh: "ઇવેન્ટની કિંમત ઘણી વધારે છે",
    someOtherPlan: "કેટલીક અન્ય યોજનાઓ છે",
    notInterested: "રસ નથી",
    reasonNotMentioned: "મારા કારણોનો અહીં ઉલ્લેખ નથી",
    rsvpSubmittedSuccessfully: "આરએસવીપી સફળતાપૂર્વક સબમિટ કર્યું!",
    errorSubmittingRSVP: "આરએસવીપી સબમિટ કરવામાં ભૂલ!",
    unauthorizedAccess: "ઍક્સેસ અનધિકૃત. મહેરબાની કરીને ફરીથી પ્રયતન કરો.",
    rsvpGuestFormSubmitted: "તમારું આરએસવીપી ગેસ્ટ ફોર્મ સફળતાપૂર્વક સબમિટ કરવામાં આવ્યું છે.",
    guestInfoSubmitted: "અતિથિ માહિતી સફળતાપૂર્વક સબમિટ કરી!",
    errorSubmittingGuestInfo: "અતિથિ માહિતી સબમિટ કરવામાં ભૂલ!",
    primaryAttendeeName: "પ્રાથમિક એટેન્ડીનું નામ",
    eventName: "ઇવેન્ટનું નામ",
    youCanNotAddGuest: "તમે આ ઇવેન્ટ માટે અતિથિઓને ઉમેરી શકતા નથી",
    noGuestsAreAdded: "કોઈ મહેમાનો ઉમેરવામાં આવ્યા નથી",
    addGuest: "અતિથિ ઉમેરો",
    yes: "હા",
    no: "ના",
    rsvpBy: "દ્વારા આરએસવીપી",
    fieldOptions: "ક્ષેત્ર વિકલ્પો",
    addCommaSeperatedOptions: "અલ્પવિરામથી વિભાજિત વિકલ્પો ઉમેરો",
    basicPlusCustomFormtext: "મૂળભૂત + કસ્ટમ ફોર્મ ફીલ્ડ આરએસવીપી ફોર્મ પર જોવામાં આવશે",
  },
  connection: {
    newConnection: "નવું કનેક્શન",
    upload: "અપલોડ કરો",
    remove: "દૂર કરો",
    website: "વેબસાઈટ",
    socialMediaLinks: "સોશિયલ મીડિયા લિંક્સ",
    addSocialMediaLink: "સોશિયલ મીડિયા લિંક ઉમેરો",
    editConnection: "કનેક્શન સંપાદિત કરો",
    groupName: "જૂથનું નામ",
    members: "સભ્યો",
    viewMember: "સભ્ય જુઓ",
    newGroup: "નવું જૂથ",
    editGroup: "જૂથ સંપાદિત કરો",
    groupMembers: "જૂથના સભ્યો",
    or: "અથવા",
    groupDeleteNote: "નોંધ: આ કોઈપણ સભ્યોને કાઢી નાખશે નહીં.",
  },
  invitation: {
    sent: "મોકલેલ",
    request: "વિનંતી",
    date: "તારીખ",
    sendInvite: "આમંત્રણ મોકલો",
    to: "પ્રતિ",
    chooseGroup: "એક જૂથ પસંદ કરો",
    eventTitleInfo:
      "ઇવેન્ટનું શીર્ષક તે ઇવેન્ટ્સ માટે પ્રદર્શિત કરવામાં આવશે જેણે આરએસવીપી અને ટિકિટ સેટિંગ્સ અસ્તિત્વમાં પ્રકાશિત કરી છે.",
    send: "મોકલો",
    viewInvite: "આમંત્રણ જુઓ",
    requested: "વિનંતી કરી",
  },
  attendees: {
    totalAmtPaid: "કુલ એએમટી ચૂકવેલ",
    maxLimit: "મહત્તમ મર્યાદા",
    accepted: "સ્વીકાર્યું",
    rejected: "ફગાવી દીધી",
    remaining: "બાકી",
    closingDate: "છેલ્લી તારીખ",
    viewAttendee: "પ્રતિભાગી જુઓ",
    payment: "ચુકવણી",
    plusOnes: "પ્લસ-વન",
    history: "ઇતિહાસ",
    personalInformation: "વ્યક્તિગત માહિતી",
    eventsAttending: "ઘટનાઓ હાજરી",
    paymentReceipt: "ચુકવણી રસીદ",
    viewAsPDF: "PDF તરીકે જુઓ",
    paymentInformation: "ચુકવણી માહિતી",
    receiptNo: "રસીદ નં",
    transactionID: "ટ્રાન્ઝેક્શન આઈડી",
    transactionDetails: "વ્યવહારની વિગતો",
    paymentMethod: "ચુકવણી પદ્ધતિ",
    tax: "કર",
    dateReceived: "તારીખ મળી",
    subTotal: "ઉપકુલ",
    totalPaymentReceived: "કુલ ચુકવણી પ્રાપ્ત થઈ",
    noPaymentReceived: "કોઈ ચુકવણી પ્રાપ્ત થઈ નથી",
    detailsOfPlusOnes: "પ્લસ-વન્સની વિગતો",
    noUserAttendeesAvailable: "કોઈ વપરાશકર્તા હાજરી ઉપલબ્ધ નથી",
    historyEmailTransactions: "ઇતિહાસ - ઇમેઇલ વ્યવહારો",
    noEmailLogsAvailable: "કોઈ ઈમેલ લોગ ઉપલબ્ધ નથી",
  },
  ticket: {
    whatYourTicketShouldConsist: "તમારી ટિકિટમાં શું હોવું જોઈએ?",
    enableAll: "બધાને સક્ષમ કરો",
    qrCodeId: "QR કોડ અને ID",
    regularVIP: "નિયમિત/વીઆઈપી",
    primaryAttendeeNamePlusOne: "પ્રાથમિક પ્રતિભાગીનું નામ (પ્લસ-વનની ગણતરી)",
    separateTicketforPlusOne: "પ્રાથમિક અને પ્લસ વન નામ માટે અલગ ટિકિટ",
    mainEventTitle: "મુખ્ય ઘટના શીર્ષક",
    individualEventTitle: "વ્યક્તિગત ઇવેન્ટ શીર્ષક",
    venue: "સ્થળ",
    eventDateTime: "ઇવેન્ટની તારીખ અને સમય",
    seats: "બેઠકો",
    notes: "નોંધો",
    termsConditions: "શરતો અને નિયમો",
    attendeeName: "હાજરી આપનારનું નામ",
  },
  checkIn: {
    checkInTime: "ચેક-ઇન સમય",
  },
  user: {
    usertype: "વપરાશકર્તા પ્રકાર",
    role: "ભૂમિકા",
    password: "પાસવર્ડ",
    newUser: "નવા વપરાશકર્તા",
    editUser: "વપરાશકર્તાને સંપાદિત કરો",
    selectUserType: "વપરાશકર્તા પ્રકાર પસંદ કરો",
    selectRole: "ભૂમિકા પસંદ કરો",
  },
  eventType: {
    eventtype: "ઇવેન્ટનો પ્રકાર",
    newEventType: "નવો ઇવેન્ટ પ્રકાર",
    editEventType: "ઇવેન્ટનો પ્રકાર સંપાદિત કરો",
  },
  userType: {
    newUserType: "નવો વપરાશકર્તા પ્રકાર",
    editUserType: "વપરાશકર્તા પ્રકાર સંપાદિત કરો",
  },
  coupon: {
    couponSavings: "કૂપન બચત",
    toPay: "ચૂકવવા માટે",
    applied: "લાગુ!",
    discount: "ડિસ્કાઉન્ટ",
    couponCriteriaNotMatch: "કૂપન માપદંડ મેળ ખાતો નથી!",
    invalidCoupon: "અમાન્ય કૂપન કોડ! કૃપા કરીને અલગ કોડ સાથે પ્રયાસ કરો.",
    selectCoupons: "કૂપન્સ પસંદ કરો",
    typeCouponCodeHere: "અહીં કૂપન કોડ લખો",
    apply: "અરજી કરો",
    viewCoupons: "કૂપન્સ જુઓ",
    copyCode: "કોડ કૉપિ કરો",
    copiedCouponCode: "કૂપન કોડ કૉપિ કર્યો",
    viewDetails: "વિગતો જુઓ",
    paymentCouponForYou: "તમારા માટે ચુકવણી કૂપન્સ",
    addCoupon: "કૂપન ઉમેરો",
    newCoupon: "નવી કૂપન",
    editCoupon: "કૂપન સંપાદિત કરો",
    validTill: "માટે માન્ય",
    fixedDiscount: "સ્થિર ડિસ્કાઉન્ટ",
    percentageDiscount: "ટકાવારી ડિસ્કાઉન્ટ",
    noOfTickets: "ટિકિટની સંખ્યા",
    couponName: "કૂપન નામ",
    couponCode: "કૂપન કોડ",
    criteria: "માપદંડ",
    field: "ક્ષેત્ર",
    type: "પ્રકાર",
    number: "નંબર",
    condition: "શરત",
    addMoreCriteria: "વધુ માપદંડ ઉમેરો",
  },
  role: {
    roleName: "ભૂમિકાનું નામ",
    newRole: "નવી ભૂમિકા",
    editRole: "ભૂમિકા સંપાદિત કરો",
    standard: "ધોરણ",
    effectedModules: "અસરગ્રસ્ત મોડ્યુલો",
    userroles: "વપરાશકર્તા ભૂમિકાઓ",
    emailtemplate: "ઈમેઈલ ટેમ્પલેટ",
    invitations: "આમંત્રણો",
  },
  memberType: {
    newMemberType: "નવો સભ્ય પ્રકાર",
    editMemberType: "સભ્યનો પ્રકાર સંપાદિત કરો",
  },
  emailTemplate: {
    id: "ID",
    keywords: "કીવર્ડ્સ",
    uniqueIdentifier: "અનન્ય ઓળખકર્તા",
    editEmailTemplate: "ઈમેઈલ ટેમ્પલેટ સંપાદિત કરો",
  },
  postEvent: {
    feedback: "પ્રતિભાવ",
    faq: "FAQ",
    agenda: "કાર્યસૂચિ",
    customForm: "કસ્ટમ ફોર્મ",
    newForm: "નવું ફોર્મ",
    questions: "પ્રશ્નો",
    question: "પ્રશ્ન",
    numberOfStar: "તારાની સંખ્યા",
    formTitle: "ફોર્મ શીર્ષક",
    addMoreQuestion: "વધુ પ્રશ્ન ઉમેરો",
    forms: "સ્વરૂપો",
    formType: "ફોર્મનો પ્રકાર",
    feedbackFrom: "પ્રતિસાદ ફોર્મ",
    agendaForm: "કાર્યસૂચિ ફોર્મ",
    faqForm: "FAQ ફોર્મ",
    customRsvpForm: "કસ્ટમ આરએસવીપી ફોર્મ",
    title: "શીર્ષક",
    startTime: "પ્રારંભ સમય",
    endTime: "સમાપ્તિ સમય",
    hostArtistName: "હોસ્ટ/કલાકારનું નામ",
    description: "વર્ણન",
    addMoreHostArtistName: "વધુ ઉમેરવા માટે અલ્પવિરામથી અલગ કરેલ મૂલ્ય ઉમેરો",
    answer: "જવાબ આપો",
    agendaSubtitle:
      "તમારી ઇવેન્ટમાં પ્રવાસ, શેડ્યૂલ અથવા લાઇનઅપ ઉમેરો. તમે સમય, શું થશે તેનું વર્ણન અને ઇવેન્ટ દરમિયાન કોણ હોસ્ટ કરશે અથવા પરફોર્મ કરશે તે શામેલ કરી શકો છો. (ઉદા. વક્તા, કલાકાર, કલાકાર, માર્ગદર્શક, વગેરે) જો તમારી ઇવેન્ટની બહુવિધ તારીખો હોય, તો તમે બીજો કાર્યસૂચિ ઉમેરી શકો છો.",
    faqSubtitle:
      "પાર્કિંગ, ઍક્સેસિબિલિટી અને રિફંડ જેવા ઇવેન્ટ વિશે તમારા પ્રતિભાગીઓના પ્રશ્નોના જવાબ આપો.",
    addMore: "વધુ ઉમેરો",
  },
  reports: {
    selectModule: "મોડ્યુલ પસંદ કરો",
    selectFields: "ક્ષેત્રો પસંદ કરો",
    generate: "પેદા",
  },
  profile: {
    account: "એકાઉન્ટ",
    general: "જનરલ",
    security: "સુરક્ષા",
    gender: "જાતિ",
    male: "પુરુષ",
    female: "સ્ત્રી",
    address: "સરનામું",
    oldPassword: "જુનો પાસવર્ડ",
    newPassword: "નવો પાસવર્ડ",
    confirmNewPassword: "નવાપાસવર્ડની પુષ્ટી કરો",
    updatePassword: "પાસવર્ડ અપડેટ કરો",
  },
  emptyState: {
    noOptions: "કોઈ વિકલ્પો નથી",
    noRowsFound: "કોઈ પંક્તિઓ મળી નથી",
    noDataAvailableSelectEvent: "કોઈ ડેટા ઉપલબ્ધ નથી, કૃપા કરીને પહેલા ઇવેન્ટ પસંદ કરો.",
    noDataAvailableSelectField: "કોઈ ડેટા ઉપલબ્ધ નથી, કૃપા કરીને પહેલા ફીલ્ડ્સ પસંદ કરો.",
    noTicketsGenerated: "આ ઇવેન્ટ માટે કોઈ ટિકિટ જનરેટ કરવામાં આવતી નથી.",
  },
  upload: {
    uploadPhoto: "અપલોડ કરો ફોટો",
    filesAllowed: "મંજૂર *.jpeg, *.jpg, *.png, *.gif",
    allowedCSV: "માત્ર *.csv ફાઇલને મંજૂરી છે",
    downloadCSV: "નમૂના CSV ફોર્મેટ ડાઉનલોડ કરો",
    maxSize: "મહત્તમ કદ {{size}}",
    dropOrSelectFile: "છોડો અથવા ફાઇલ પસંદ કરો",
    dropFilesHereOrBrowse:
      "ફાઇલો અહીં મૂકો અથવા તમારા મશીન દ્વારા <1> બ્રાઉઝ કરો </1> પર ક્લિક કરો",
  },
  toast: {
    success: {
      login: "વપરાશકર્તા સફળતાપૂર્વક લૉગ ઇન થયા",
      logout: "વપરાશકર્તા સફળતાપૂર્વક લૉગ આઉટ થયો.",
      loginAgain: "કૃપા કરીને ફરીથી લૉગિન કરો!",
      inactiveLogout: "નિષ્ક્રિયતાને કારણે લૉગ આઉટ કર્યું",
      deleteCompany: "કંપનીનો રેકોર્ડ સફળતાપૂર્વક કાઢી નાખવામાં આવ્યો!",
      deleteConnection: "કનેક્શન સફળતાપૂર્વક કાઢી નાખ્યું!",
      deleteGroup: "જૂથ સફળતાપૂર્વક કાઢી નાખ્યું!",
      updateGroup: "જૂથ સફળતાપૂર્વક અપડેટ થયું!",
      resetPasswordLink: "તમને ટૂંક સમયમાં પાસવર્ડ રીસેટ કરવાની લિંક પ્રાપ્ત થશે!",
      deleteInvitation: "આમંત્રણ રેકોર્ડ સફળતાપૂર્વક કાઢી નાખ્યો!",
      deletePlan: "પ્લાન સફળતાપૂર્વક કાઢી નાખ્યો!",
    },
    error: {
      postEventList: "પોસ્ટ ઇવેન્ટ સૂચિ લાવવામાં ભૂલ",
      action: "ઓપરેશન કરવામાં નિષ્ફળ!",
      companyList: "કંપનીની સૂચિ મેળવવામાં ભૂલ",
      connectionList: "કનેક્શન્સ લાવવામાં ભૂલ",
      deleteConnection: "કનેક્શન કાઢી નાખવામાં ભૂલ",
      groupList: "જૂથો લાવવામાં ભૂલ",
      deleteGroup: "જૂથ કાઢી નાખવામાં ભૂલ",
      selectMember: "કૃપા કરીને ઓછામાં ઓછો એક સભ્ય પસંદ કરો!",
      checkinList: "ચેક ઇન સૂચિ લાવવામાં ભૂલ",
      eventRequiredFields: "કૃપા કરીને જરૂરી ફીલ્ડ ભરો: ઇવેન્ટનો પ્રકાર, મોડ, શીર્ષક અને તારીખ સમય",
      subEventRequiredFields:
        "કૃપા કરીને પેટા ઇવેન્ટમાં જરૂરી ફીલ્ડ ભરો: ઇવેન્ટનો પ્રકાર, મોડ, શીર્ષક અને તારીખ સમય",
      eventCapacity: "પિતૃ ઇવેન્ટ ક્ષમતા માન્ય નથી, કૃપા કરીને 0 કરતાં વધુ મૂલ્ય દાખલ કરો",
      subEventCapacity: "સબ ઇવેન્ટ ક્ષમતા માન્ય નથી, કૃપા કરીને 0 કરતાં વધુ મૂલ્ય દાખલ કરો",
      eventList: "ઇવેન્ટ્સ લાવવામાં ભૂલ",
      invitationList: "આમંત્રણો મેળવવામાં ભૂલ",
      invitationRequiredFields:
        "કૃપા કરીને પ્રાપ્તકર્તા ઇમેઇલ ઉમેરો અથવા ઓછામાં ઓછું એક જૂથ પસંદ કરો!",
      invalidCredentials: "વપરાશકર્તા નામ અથવા પાસવર્ડ ખોટો છે",
      eventTypeList: "ઇવેન્ટ પ્રકારો લાવવામાં ભૂલ",
      memberTypeList: "સભ્ય પ્રકારનું આનયન કરવામાં ભૂલ",
      roleList: "ભૂમિકાઓ મેળવવામાં ભૂલ",
      userList: "વપરાશકર્તાઓને લાવવામાં ભૂલ",
      userType: "વપરાશકર્તા પ્રકારો લાવવામાં ભૂલ",
      plan: "યોજનાઓ લાવવામાં ભૂલ",
      passwordIncorrect: "પાસવર્ડ ખોટો છે",
      rsvpList: "RSVP સૂચિ મેળવવામાં ભૂલ",
      deleteRSVP: "RSVP રેકોર્ડ કાઢી નાખવામાં ભૂલ",
      rsvpLessThanEvent: "RSVP તારીખ ઇવેન્ટની તારીખ ({{date}}) કરતાં ઓછી હોવી જોઈએ",
      firstReminderLessThanEvent:
        "1લી રીમાઇન્ડરની તારીખ ઇવેન્ટની તારીખ ({{date}}) કરતાં ઓછી હોવી જોઈએ",
      secondReminderGreaterThanFirstReminder:
        "2જી રીમાઇન્ડરની તારીખ 1લી રીમાઇન્ડરની તારીખ કરતા મોટી હોવી જોઈએ",
      secondReminderLessThanEvent:
        "2જી રીમાઇન્ડરની તારીખ ઇવેન્ટની તારીખ ({{date}}) કરતાં ઓછી હોવી જોઈએ",
      customFormCheck: "કૃપા કરીને કસ્ટમ ફોર્મ ચેકબોક્સ ચેક કરો",
    },
  },
  company: {
    companyList: "કંપની યાદી",
    createCompany: "કંપની બનાવો",
    plan: "યોજના",
    planEnd: "યોજના સમાપ્ત",
    domain: "ડોમેન",
    editCompany: "કંપની સંપાદિત કરો",
    companyName: "કંપની નું નામ",
    companyEmail: "કંપની ઈમેઈલ",
    companyPhone: "કંપની ફોન નં.",
    industryType: "ઉદ્યોગનો પ્રકાર",
    noOfEmployee: "કર્મચારીની સંખ્યા",
    companyWebsite: "કંપનીની વેબસાઇટ",
    companyAddress: "કંપનીનું સરનામું",
    selectCountry: "દેશ પસંદ કરો",
    selectState: "રાજ્ય પસંદ કરો",
    selectCity: "શહેર પસંદ કરો",
    selectPlan: "પ્લાન પસંદ કરો",
    manageConfig: "રૂપરેખા મેનેજ કરો",
    eventCreateLimit: "ઇવેન્ટ બનાવવાની મર્યાદા",
    connectionLimit: "કનેક્શન મર્યાદા",
    invitationSentLimit: "આમંત્રણ મોકલવાની મર્યાદા",
    userCreateLimit: "વપરાશકર્તા સર્જન મર્યાદા",
    postEventCreateLimit: "પોસ્ટ ઇવેન્ટ ફોર્મ બનાવવાની મર્યાદા",
    domainSubscription: "ડોમેન સબ્સ્ક્રિપ્શન સમાપ્તિ તારીખ",
    publicEventRequestAllow: "અનામી વિનંતીઓને મંજૂરી આપો",
    customFormAllow: "કસ્ટમ ફોર્મ બનાવવાની મંજૂરી આપો",
    agendaAllow: "કાર્યસૂચિ બનાવવાની મંજૂરી આપો",
    faqAllow: "FAQ બનાવવાની મંજૂરી આપો",
    eventFeedbackFormAllow: "ઇવેન્ટ ફીડબેક ફોર્મ બનાવવાની મંજૂરી આપો",
    passwordValidation: "પાસવર્ડ ઓછામાં ઓછો 8+ હોવો જોઈએ",
  },
  plan: {
    createPlan: "યોજના બનાવો",
    editPlan: "યોજના સંપાદિત કરો",
    planName: "યોજનાનું નામ",
    planType: "યોજનાનો પ્રકાર",
    planDetails: "યોજના વિગતો",
    monthly: "માસિક",
    quarterly: "ત્રિમાસિક",
    biannually: "દ્વિવાર્ષિક",
    yearly: "વાર્ષિક",
    price: "કિંમત",
    billing: "બિલિંગ",
  },
  dashboard: {
    eventsByModes: "મોડ્સ દ્વારા ઇવેન્ટ્સ",
    invited: "આમંત્રિત",
    requestedSocialMedia: "વિનંતી કરેલ (સોશિયલ મીડિયા)",
    eventTypeStatistics: "ઘટના પ્રકાર આંકડા",
    noOfAttendeeStatistics: "પ્રતિભાગીઓની સંખ્યા આંકડા",
    noOfTicketsPurchasedStatistics: "ખરીદેલી ટિકિટોની સંખ્યા આંકડા",
    eventBaseTicketAndCheckIn: "ઇવેન્ટના આધારે ટિકિટ/ચેક-ઇનની સંખ્યા",
    eventBaseTicketAndCheckInInfo:
      "ડિફૉલ્ટ રૂપે, બધી ઇવેન્ટ્સનું પૂર્વાવલોકન કરવામાં આવે છે જેમાં સબ ઇવેન્ટ નથી, પેટા ઇવેન્ટ્સની તુલના કરવા માટે આંતરિક રીતે ઇવેન્ટનું નામ પસંદ કરો",
    allEvents: "બધી ઘટનાઓ",
    paid: "ચૂકવેલ",
  },
  organization: {
    myOrganization: "મારી સંસ્થા",
    companyDetails: "કંપનીની વિગતો",
    details: "વિગતો",
    plan: "યોજના",
    help: "મદદ",
    Label: {
      logo: "લોગો",
      name: "નામ",
      email: "ઈમેલ",
      contactNo: "સંપર્ક નં",
      address: "સરનામું",
      domain: "ડોમેન",
      country: "દેશ",
      state: "રાજ્ય",
      city: "શહેર",
      website: "વેબસાઈટ",
      noOfEmployee: "કર્મચારીની સંખ્યા",
      industryType: "ઉદ્યોગનો પ્રકાર",
      planId: "યોજનાનું આઈડી",
      planName: "યોજનાનું નામ",
      planStartDate: "યોજનાની શરૂઆતની તારીખ",
      planEndtDate: "યોજના સમાપ્તિ તારીખ",
      cancelPlan: "પ્લાન રદ કરો",
      upgradePlan: "અપગ્રેડ પ્લાન",
      subject: "વિષય",
      description: "વર્ણન",
      submit: "સબમિટ કરો",
      agendaAllow: "કાર્યસૂચિ પરવાનગી આપે છે",
      customFormAllow: "કસ્ટમ ફોર્મ મંજૂરી આપો",
      domainSubscription: "ડોમેન સબ્સ્ક્રિપ્શન",
      eventFeedbackFormAllow: "ઇવેન્ટ ફીડબેક ફોર્મની મંજૂરી આપો",
      eventCreateLimit: "ઇવેન્ટ બનાવો મર્યાદા",
      faqAllow: "FAQ મંજૂરી આપો",
      publicEventRequestAllow: "જાહેર ઇવેન્ટ વિનંતીને મંજૂરી આપો",
      invitationSentLimit: "આમંત્રણ મોકલવાની મર્યાદા",
      postEventCreateLimit: "ઇવેન્ટ પછી મર્યાદા બનાવો",
      connectionLimit: "કનેક્શન મર્યાદા",
      userCreateLimit: "વપરાશકર્તા મર્યાદા બનાવો",
      current: "વર્તમાન",
      configurations: "રૂપરેખાંકનો",
    },
  },
}

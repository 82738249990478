export default {
  global: {
    welcome: "नमस्ते, पुनः स्वागत है",
    admin: "व्यवस्थापक",
    dashboard: "डैशबोर्ड",
    events: "ईवेंट",
    rsvp: "आरएसवीपी",
    connections: "कनेक्शनस",
    groups: "समूह",
    invitation: "आमंत्रण",
    eventOperations: "इवेंट संचालन",
    master: "मालिक",
    postEvent: "घटना के बाद",
    reports: "रिपोर्टस",
    attendees: "सहभागी",
    tickets: "टिकट",
    checkin: "चेक इन",
    users: "उपयोगकर्ताओं",
    eventTypes: "इवेंट के प्रकार",
    userTypes: "उपयोगकर्ता प्रकार",
    roles: "भूमिकाएँ",
    memberType: "सदस्य प्रकार",
    emailTemplate: "ईमेल टेम्पलेट",
    coupons: "कूपन",
    company: "कंपनी",
    plans: "योजनाओं",
    home: "घर",
    profile: "प्रोफ़ाइल",
    logout: "लॉग आउट",
    action: "कार्रवाई",
    edit: "संपादन करना",
    add: "जोड़ना",
    delete: "मिटाना",
    save: "बचाना",
    update: "अद्यतन",
    view: "देखना",
    search: "खोज",
    cancel: "रद्द करना",
    preview: "पूर्व दर्शन",
    deleteMsg: "क्या आप वाकई हटाना चाहते हैं?",
    cancelMsg: "क्या आपको वाकई रद्द करना है?",
    name: "नाम",
    firstName: "पहला नाम",
    lastName: "उपनाम",
    email: "ईमेल",
    phoneNumber: "फ़ोन नंबर",
    phone: "फ़ोन",
    status: "स्थिति",
    submit: "जमा करना",
    createdOn: "पर बनाया",
    active: "सक्रिय",
    inactive: "निष्क्रिय",
    yes: "हाँ",
    no: "नहीं",
    title: "शीर्षक",
  },
  event: {
    selectEventMode: "इवेंट मोड चुनें",
    offline: "ऑफलाइन",
    online: "ऑनलाइन",
    hybrid: "हाइब्रिड",
    upcoming: "आगामी",
    ongoing: "चल रहे",
    completed: "पुरा होना।",
    createEvent: "ईवेंट बनाएँ",
    noEventsFound: "कोई ईवेंट नहीं मिला",
    duplicate: "डुप्लिकेट",
    addEvent: "ईवेंट जोड़ें",
    selectEventType: "ईवेंट प्रकार चुनें",
    eventTitle: "ईवेंट का शीर्षक",
    startDate: "आरंभ करने की तिथि",
    endDate: "अंतिम तिथि",
    eventDescription: "इवेंट विवरण",
    writeSomethingAwesome: "कुछ अद्भुत लिखें...",
    eventInstructions: "इवेंट निर्देश",
    eventCapacity: "इवेंट क्षमता",
    paid: "चुकाया गया?",
    eventPrice: "इवेंट मूल्य",
    addLocation: "स्थान जोड़ना",
    uploadCoverImage: "कवर छवि अपलोड करें",
    uploadFloorPlan: "फ़्लोर प्लान अपलोड करें",
    addSubEvent: "उप इवेंट जोड़ें",
    subEvents: "उप इवेंट",
    location: "जगह",
    eventLocation: "इवेंट स्थान",
    locationRequiredMsg: "कृपया स्थान दर्ज करें",
    multipleLocations: "एकाधिक स्थान",
    crewMembers: "चालक दल के सदस्यों",
    selectMemberType: "सदस्य प्रकार चुनें",
    addMember: "सदस्य जोड़ें",
    updateOngoingEventMsg: "आप चल रही या पिछली घटनाओं को संशोधित नहीं कर सकते.",
    updateRSVPEventMsg:
      "कृपया ईवेंट को संशोधित करने से पहले आरएसवीपी और अन्य संबंधित डेटा हटा दें।",
    deleteOngoingEventMsg: "आप चल रहे या पिछले ईवेंट को हटा नहीं सकते.",
    deleteRSVPEventMsg: "कृपया ईवेंट हटाने से पहले आरएसवीपी और अन्य संबंधित डेटा हटा दें।",
    editEvent: "इवेंट संपादित करें",
    free: "मुक्त",
    about: "के बारे में",
    description: "विवरण",
    noDescriptionAdded: "कोई विवरण नहीं जोड़ा गया",
    instructions: "निर्देश",
    noInstructionsAdded: "कोई निर्देश नहीं जोड़ा गया",
    startDateTime: "प्रारंभ दिनांक/समय",
    endDateTime: "समाप्ति दिनांक/समय",
    eventMode: "इवेंट मोड",
    selectEventTitle: "ईवेंट शीर्षक चुनें",
    event: "आयोजन",
    importEvent: "आयात ईवेंट",
    importRSVP: "आरएसवीपी आयात करें",
    importEventWithRSVP: "आरएसवीपी के साथ इवेंट बनाएं",
    singleEvent: "एकल ईवेंट",
    multipleEvents: "एकाधिक ईवेंट",
    viewEvent: "ईवेंट देखें",
    saveAsDraft: "ड्राफ्ट के रूप में सेव करें",
    noLocations: "कोई स्थान नहीं",
    noLink: "कोई लिंक नहीं",
    noLinkProvidedbyContact: "संपर्क द्वारा कोई लिंक उपलब्ध नहीं कराया गया",
    addOns: "ऐड-ऑन",
    eventFeatures: "घटना की विशेषताएं",
    feedbackForm: "प्रतिपुष्टी फ़ार्म",
    feedbackFormDescription:
      "इवेंट के बाद उपस्थित लोगों द्वारा भरे जाने वाले कस्टम फीडबैक फॉर्म बनाएं",
    agenda: "कार्यसूची",
    agendaDescription: "इवेंट एजेंडा और उनकी टीम के सदस्यों को परिभाषित करें",
    faqs: "पूछे जाने वाले प्रश्न",
    faqDescription: "इवेंट दर्शकों के लिए एक FAQ अनुभाग बनाएं",
    customRSVP: "कस्टम आरएसवीपी",
    customRSVPDescription: "इवेंट के लिए आरएसवीपी फॉर्म परिभाषित करें",
    selectFromExisting: "मौजूदा में से चुनें",
    createYourOwn: "अपना स्वयं का बनाएं",
    selectFeedbackForm: "फीडबैक फॉर्म चुनें",
    selectAgenda: "एजेंडा चुनें",
    selectFAQ: "अक्सर पूछे जाने वाले प्रश्न चुनें",
    selectRSVPForm: "आरएसवीपी फॉर्म चुनें",
    requestFeedback: "प्रतिक्रिया का अनुरोध करें",
    userResponses: "उपयोगकर्ता प्रतिक्रियाएँ",
    noMembersAdded: "कोई सदस्य नहीं जोड़ा गया",
    viewingDraftEvent: "आप एक ड्राफ्ट इवेंट देख रहे हैं.",
    reoccurringFromPreviousEvent: "पिछली घटना से पुनः घटित हो रहा है?",
  },
  rsvp: {
    requiredFieldAtleastone: "कृपया कम से कम एक विकल्प चुनें",
    requiredField: "आवश्यक क्षेत्र",
    published: "प्रकाशित",
    draft: "मसौदा",
    cancelled: "रद्द",
    formDesign: "फॉर्म डिजाइन",
    details: "विवरण",
    createdBy: "के द्वारा बनाई गई",
    copyRSVP: "आरएसवीपी कॉपी करें",
    addRSVP: "आरएसवीपी जोड़ें",
    editRSVP: "आरएसवीपी संपादित करें",
    viewRSVP: "आरएसवीपी देखें",
    generalSettings: "सामान्य सेटिंग्स",
    designForm: "डिज़ाइन फॉर्म",
    configureReminders: "अनुस्मारक कॉन्फ़िगर करें",
    viewPublish: "देखें और प्रकाशित करें",
    rsvpByDate: "तिथि के अनुसार आरएसवीपी",
    rsvpByTime: "समय के अनुसार आरएसवीपी",
    allowGuestsToDo: "मेहमानों को ऐसा करने दें",
    individualRSVP: "व्यक्तिगत आरएसवीपी",
    setLimitForPlusOnes: "प्लस-वन के लिए सीमा निर्धारित करें",
    mentionPlusOne:
      "क्या प्राथमिक आमंत्रित व्यक्ति को प्लस-वन के नाम का उल्लेख करने की आवश्यकता है?",
    emailAddressSupport: "ईमेल पता (समर्थन)",
    contactNoSupport: "संपर्क नंबर (सहायता)",
    firstReminderMsg:
      "प्राथमिक सहभागी के लिए अपने प्लस-वन के नाम सबमिट करने के लिए पहला अनुस्मारक ईमेल सेट करें",
    secondReminderMsg:
      "प्राथमिक सहभागी के लिए अपने प्लस-वन के नाम सबमिट करने के लिए दूसरा/अंतिम अनुस्मारक ईमेल डेटा सेट करें",
    firstReminderInfo: `यह ईमेल प्राथमिक <br /> सहभागी को अपने प्लस-वन का नाम सबमिट करने के लिए <br /> पहले अनुस्मारक के रूप में भेजा जाएगा।`,
    firstReminderDate: "प्राथमिक सहभागी जानकारी के लिए प्रथम अनुस्मारक दिनांक",
    secondReminderDate: "प्राथमिक सहभागी जानकारी के लिए दूसरी अनुस्मारक तिथि",
    prev: "पिछला",
    next: "अगला",
    rsvpFormDesign: "आरएसवीपी फॉर्म डिजाइन",
    basic: "बुनियादी",
    custom: "रिवाज़",
    basicFormFields: "मूल प्रपत्र फ़ील्ड",
    customFormFields: "कस्टम फॉर्म फ़ील्ड",
    selectCustomTemplate: "कस्टम फॉर्म टेम्पलेट चुनें",
    selectTemplate: "टेम्प्लेट चुनें",
    pleaseLetUsKnow: "कृपया हमें बताएं कि क्या आप इसे बनाने में सक्षम होंगे।",
    yesIllBeThere: "हाँ, मैं वहाँ रहूँगा",
    noIcantMakeIt: "नहीं, मैं इसे नहीं बना सकता",
    useBasicFormMsg: "मैं अपने इवेंट के लिए बुनियादी फॉर्म का उपयोग करना चाहूंगा",
    useForm: "फॉर्म का प्रयोग करें",
    typeAQuestion: "एक प्रश्न टाइप करें",
    selectFieldType: "फ़ील्ड प्रकार चुनें",
    phone: "फ़ोन",
    textbox: "पाठ बॉक्स",
    textarea: "पाठ क्षेत्र",
    checkbox: "चेक बॉक्स",
    dropdown: "ड्रॉप डाउन",
    radioButton: "रेडियो की बटन",
    addFormElement: "प्रपत्र तत्व जोड़ें",
    useCustomFormMsg: "मैं अपने ईवेंट के लिए कस्टम फॉर्म का उपयोग करना चाहूंगा",
    designEmailTemplates: "डिफ़ॉल्ट या आवर्ती ईमेल टेम्पलेट डिज़ाइन करें",
    default: "गलती करना",
    reminder: "अनुस्मारक",
    messageWhen: "संदेश कब",
    rsvpIsYes: "आरएसवीपी हाँ है",
    rsvpIsNo: "आरएसवीपी नंबर है",
    reminderDate1st: "सहभागी विवरण के लिए पहली अनुस्मारक तिथि आ गई है",
    reminderDate2nd: "सहभागी विवरण के लिए दूसरी अनुस्मारक तिथि आ गई है",
    registrationClosed: "पंजीकरण बंद किया जाना है",
    eventDateApproached: "आयोजन की तारीख नजदीक आ गई है",
    subject: "विषय",
    typeAMessage: "एक संदेश लिखें",
    attachPaymentReceipt: "भुगतान रसीद संलग्न करें",
    sendLinkToNamePlusOnes: "प्लस-वन के नाम के लिए लिंक भेजें",
    previewToRsvp: "RSVP का पूर्वावलोकन",
    contactInfo: "संपर्क सूचना",
    reservation: "आरक्षण",
    publishRSVPPage: "आरएसवीपी पेज प्रकाशित करें",
    rsvpLinkCopied: "आपका आरएसवीपी लिंक कॉपी कर लिया गया है।",
    thankYou: "धन्यवाद!",
    alreadySubmitted: "आप पहले ही आरएसवीपी फॉर्म जमा कर चुके हैं।",
    reachUsForAssistance: "किसी भी सहायता के लिए आप हमसे संपर्क कर सकते हैं:",
    formSubmitted: "आपका आरएसवीपी फॉर्म सफलतापूर्वक जमा कर दिया गया है।",
    eventAtFullCapacity: "यह आयोजन पूरी क्षमता पर है.",
    thankYouForYourInterest: "अपनी रुचि दिखाने के लिए धन्यवाद!",
    rsPerPerson: "रुपये/व्यक्ति",
    total: "कुल:",
    payWithCard: "कार्ड से भुगतान करें",
    payNow: "अब भुगतान करें",
    reasonToRefuse: "मना करने का कारण",
    additionalComments: "अतिरिक्त टिप्पणियाँ / अपना कारण बताएं",
    dateNotSuitable: "तारीख उपयुक्त नहीं",
    timeNotSuitable: "समय उपयुक्त नहीं है",
    priceTooHigh: "इवेंट की कीमत बहुत अधिक है",
    someOtherPlan: "कुछ और योजनाएं हैं",
    notInterested: "दिलचस्पी नहीं है",
    reasonNotMentioned: "मेरे कारणों का उल्लेख यहां नहीं किया गया है",
    rsvpSubmittedSuccessfully: "आरएसवीपी सफलतापूर्वक सबमिट किया गया!",
    errorSubmittingRSVP: "आरएसवीपी सबमिट करने में त्रुटि!",
    unauthorizedAccess: "अनधिकृत प्रवेश. कृपया पुन: प्रयास करें।",
    rsvpGuestFormSubmitted: "आपका आरएसवीपी गेस्ट फॉर्म सफलतापूर्वक सबमिट कर दिया गया है।",
    guestInfoSubmitted: "अतिथि जानकारी सफलतापूर्वक सबमिट की गई!",
    errorSubmittingGuestInfo: "अतिथि जानकारी सबमिट करने में त्रुटि!",
    primaryAttendeeName: "प्राथमिक सहभागी का नाम",
    eventName: "घटना नाम",
    youCanNotAddGuest: "आप इस ईवेंट में अतिथियों को शामिल नहीं कर सकते",
    noGuestsAreAdded: "कोई अतिथि नहीं जोड़ा गया है",
    addGuest: "अतिथि जोड़ें",
    yes: "हाँ",
    no: "नहीं",
    rsvpBy: "आरएसवीपी द्वारा",
    fieldOptions: "फ़ील्ड विकल्प",
    addCommaSeperatedOptions: "अल्पविराम से अलग किए गए विकल्प जोड़ें",
    basicPlusCustomFormtext: "बेसिक + कस्टम फॉर्म फ़ील्ड आरएसवीपी फॉर्म पर दिखाई देंगे",
  },
  connection: {
    newConnection: "नया कनेक्शन",
    upload: "डालना",
    remove: "निकालना",
    website: "वेबसाइट",
    socialMediaLinks: "सोशल मीडिया लिंक",
    addSocialMediaLink: "सोशल मीडिया लिंक जोड़ें",
    editConnection: "कनेक्शन संपादित करें",
    groupName: "समूह नाम",
    members: "सदस्यों",
    viewMember: "सदस्य देखें",
    newGroup: "नया समूह",
    editGroup: "समूह संपादित करें",
    groupMembers: "समूह के सदस्यों को",
    or: "या",
    groupDeleteNote: "नोट: इससे कोई भी सदस्य नहीं हटेगा.",
  },
  invitation: {
    sent: "भेजा",
    request: "अनुरोध",
    date: "तारीख",
    sendInvite: "आमंत्रण भेजो",
    to: "को",
    chooseGroup: "एक समूह चुनें",
    eventTitleInfo:
      "इवेंट का शीर्षक उन इवेंट के लिए प्रदर्शित किया जाएगा जिनमें आरएसवीपी और टिकट सेटिंग मौजूद हैं।",
    send: "भेजना",
    viewInvite: "आमंत्रण देखें",
    requested: "का अनुरोध किया",
  },
  attendees: {
    totalAmtPaid: "भुगतान की गई कुल राशि",
    maxLimit: "अधिकतम सीमा",
    accepted: "स्वीकृत",
    rejected: "अस्वीकार कर दिया",
    remaining: "शेष",
    closingDate: "अंतिम तिथि",
    viewAttendee: "सहभागी को देखें",
    payment: "भुगतान",
    plusOnes: "प्लस-वन",
    history: "इतिहास",
    personalInformation: "व्यक्तिगत जानकारी",
    eventsAttending: "आयोजनों में भाग लेना",
    paymentReceipt: "भुगतान रसीद",
    viewAsPDF: "पीडीएफ के रूप में देखें",
    paymentInformation: "भुगतान जानकारी",
    receiptNo: "रसीद संख्या",
    transactionID: "लेन-देन आईडी",
    transactionDetails: "लेनदेन का विवरण",
    paymentMethod: "भुगतान विधि",
    tax: "कर",
    dateReceived: "प्राप्ति दिनांक",
    subTotal: "उप-योग",
    totalPaymentReceived: "कुल भुगतान प्राप्त हुआ",
    noPaymentReceived: "कोई भुगतान प्राप्त नहीं हुआ",
    detailsOfPlusOnes: "प्लस-वन का विवरण",
    noUserAttendeesAvailable: "कोई उपयोगकर्ता उपस्थित उपलब्ध नहीं है",
    historyEmailTransactions: "इतिहास - ईमेल लेनदेन",
    noEmailLogsAvailable: "कोई ईमेल लॉग उपलब्ध नहीं है",
  },
  ticket: {
    whatYourTicketShouldConsist: "आपके टिकट में क्या होना चाहिए?",
    enableAll: "सभी को सक्षम करें",
    qrCodeId: "क्यूआर कोड और आईडी",
    regularVIP: "नियमित/वीआईपी",
    primaryAttendeeNamePlusOne: "प्राथमिक सहभागी का नाम (प्लस-वन गिनती)",
    separateTicketforPlusOne: "प्राइमरी और प्लस वन के नाम के लिए अलग टिकट",
    mainEventTitle: "मुख्य कार्यक्रम का शीर्षक",
    individualEventTitle: "व्यक्तिगत इवेंट शीर्षक",
    venue: "कार्यक्रम का स्थान",
    eventDateTime: "इवेंट की तारीख और समय",
    seats: "सीटें",
    notes: "टिप्पणियाँ",
    termsConditions: "नियम एवं शर्तें",
    attendeeName: "सहभागी का नाम",
  },
  checkIn: {
    checkInTime: "समय पर जांचो",
  },
  user: {
    usertype: "उपयोगकर्ता का प्रकार",
    role: "भूमिका",
    password: "पासवर्ड",
    newUser: "नए उपयोगकर्ता",
    editUser: "यूजर को संपादित करो",
    selectUserType: "उपयोगकर्ता प्रकार चुनें",
    selectRole: "भूमिका चुनें",
  },
  eventType: {
    eventtype: "इवेंट प्रकार",
    newEventType: "नया इवेंट प्रकार",
    editEventType: "ईवेंट प्रकार संपादित करें",
  },
  userType: {
    newUserType: "नया उपयोगकर्ता प्रकार",
    editUserType: "उपयोगकर्ता प्रकार संपादित करें",
  },
  coupon: {
    couponSavings: "कूपन बचत",
    toPay: "भुगतान करने के लिए",
    applied: "लागू!",
    discount: "छूट",
    couponCriteriaNotMatch: "कूपन मानदंड मेल नहीं खाता!",
    invalidCoupon: "अमान्य कूपन कोड! कृपया भिन्न कोड के साथ प्रयास करें.",
    selectCoupons: "कूपन चुनें",
    typeCouponCodeHere: "यहां कूपन कोड टाइप करें",
    apply: "आवेदन करना",
    viewCoupons: "कूपन देखें",
    copyCode: "कोड कॉपी करें",
    copiedCouponCode: "कूपन कोड कॉपी किया गया",
    viewDetails: "विवरण देखें",
    paymentCouponForYou: "आपके लिए भुगतान कूपन",
    addCoupon: "कूपन जोड़ें",
    newCoupon: "नया कूपन",
    editCoupon: "कूपन संपादित करें",
    validTill: "मान्य के लिए",
    fixedDiscount: "निश्चित छूट",
    percentageDiscount: "प्रतिशत छूट",
    noOfTickets: "टिकटों की संख्या",
    couponName: "कूपन का नाम",
    couponCode: "कूपन कोड",
    criteria: "मानदंड",
    field: "मैदान",
    type: "प्रकार",
    number: "संख्या",
    condition: "स्थिति",
    addMoreCriteria: "अधिक मानदंड जोड़ें",
  },
  role: {
    roleName: "भूमिका का नाम",
    newRole: "नयी भूमिका",
    editRole: "भूमिका संपादित करें",
    standard: "मानक",
    effectedModules: "प्रभावित मॉड्यूल",
    userroles: "उपयोगकर्ता भूमिका",
    emailtemplate: "ईमेल टेम्पलेट",
    invitations: "आमंत्रण",
  },
  memberType: {
    newMemberType: "नये सदस्य प्रकार",
    editMemberType: "सदस्य प्रकार संपादित करें",
  },
  emailTemplate: {
    id: "पहचान",
    keywords: "कीवर्ड",
    uniqueIdentifier: "विशिष्ट पहचानकर्ता",
    editEmailTemplate: "ईमेल टेम्पलेट संपादित करें",
  },
  postEvent: {
    feedback: "प्रतिक्रिया",
    faq: "FAQ",
    agenda: "कार्यसूची",
    customForm: "कस्टम फॉर्म",
    newForm: "नए रूप मे",
    questions: "प्रशन",
    question: "सवाल",
    numberOfStar: "स्टार की संख्या",
    formTitle: "प्रपत्र शीर्षक",
    addMoreQuestion: "और प्रश्न जोड़ें",
    forms: "फार्म",
    formType: "प्रपत्र प्रकार",
    feedbackFrom: "प्रतिपुष्टी फ़ार्म",
    agendaForm: "एजेंडा प्रपत्र",
    faqForm: "अक्सर पूछे जाने वाले प्रश्न प्रपत्र",
    customRsvpForm: "कस्टम आरएसवीपी फॉर्म",
    title: "शीर्षक",
    startTime: "समय शुरू",
    endTime: "अंत समय",
    hostArtistName: "मेज़बान/कलाकार का नाम",
    description: "विवरण",
    addMoreHostArtistName: "अधिक जोड़ने के लिए अल्पविराम से अलग किया गया मान जोड़ें",
    answer: "उत्तर",
    agendaSubtitle:
      "अपने ईवेंट में एक यात्रा कार्यक्रम, शेड्यूल या लाइनअप जोड़ें। आप एक समय, क्या होगा इसका विवरण, और आयोजन के दौरान कौन मेजबानी करेगा या प्रदर्शन करेगा, शामिल कर सकते हैं। (उदा. वक्ता, कलाकार, कलाकार, मार्गदर्शक, आदि) यदि आपके कार्यक्रम में कई तारीखें हैं, तो आप दूसरा एजेंडा जोड़ सकते हैं।",
    faqSubtitle:
      "आपके उपस्थित लोगों के इवेंट के बारे में पार्किंग, पहुंच और रिफंड जैसे प्रश्नों के उत्तर दें।",
    addMore: "अधिक जोड़ें",
  },
  reports: {
    selectModule: "मॉड्यूल का चयन करें",
    selectFields: "फ़ील्ड चुनें",
    generate: "उत्पन्न",
  },
  profile: {
    account: "खाता",
    general: "सामान्य",
    security: "सुरक्षा",
    gender: "लिंग",
    male: "पुरुष",
    female: "महिला",
    address: "पता",
    oldPassword: "पुराना पासवर्ड",
    newPassword: "नया पासवर्ड",
    confirmNewPassword: "नए पासवर्ड की पुष्टि करें",
    updatePassword: "पासवर्ड अपडेट करें",
  },
  emptyState: {
    noOptions: "कोई विकल्प नहीं",
    noRowsFound: "कोई पंक्तियाँ नहीं मिलीं",
    noDataAvailableSelectEvent: "कोई डेटा उपलब्ध नहीं है, कृपया पहले ईवेंट चुनें।",
    noDataAvailableSelectField: "कोई डेटा उपलब्ध नहीं है, कृपया पहले फ़ील्ड चुनें।",
    noTicketsGenerated: "इस आयोजन के लिए कोई टिकट नहीं बनाया गया है.",
  },
  upload: {
    uploadPhoto: "फोटो अपलोड करें",
    filesAllowed: "*.jpeg, *.jpg, *.png, *.gif की अनुमति है",
    allowedCSV: "केवल *.csv फ़ाइल की अनुमति है",
    downloadCSV: "एक नमूना सीएसवी प्रारूप डाउनलोड करें",
    maxSize: "अधिकतम आकार {{size}}",
    dropOrSelectFile: "फ़ाइल छोड़ें या चुनें",
    dropFilesHereOrBrowse:
      "फ़ाइलें यहां छोड़ें या अपनी मशीन के माध्यम से <1> ब्राउज़ करें </1> पर क्लिक करें",
  },
  toast: {
    success: {
      login: "उपयोगकर्ता सफलतापूर्वक लॉग इन हुआ",
      logout: "उपयोगकर्ता सफलतापूर्वक लॉग आउट हो गया.",
      loginAgain: "कृपया फिर भाग लें!",
      inactiveLogout: "निष्क्रियता के कारण लॉग आउट हो गया",
      deleteCompany: "कंपनी रिकॉर्ड सफलतापूर्वक हटा दिया गया!",
      deleteConnection: "कनेक्शन सफलतापूर्वक हटा दिया गया!",
      deleteGroup: "समूह सफलतापूर्वक हटा दिया गया!",
      updateGroup: "समूह सफलतापूर्वक अपडेट किया गया!",
      resetPasswordLink: "आपको शीघ्र ही पासवर्ड रीसेट लिंक प्राप्त होगा!",
      deleteInvitation: "आमंत्रण रिकॉर्ड सफलतापूर्वक हटा दिया गया!",
      deletePlan: "योजना सफलतापूर्वक हटा दी गई!",
    },
    error: {
      postEventList: "पोस्ट इवेंट सूची लाने में त्रुटि",
      action: "ऑपरेशन करने में विफल!",
      companyList: "कंपनी सूची लाने में त्रुटि",
      connectionList: "कनेक्शन लाने में त्रुटि",
      deleteConnection: "कनेक्शन हटाने में त्रुटि",
      groupList: "समूह लाने में त्रुटि",
      deleteGroup: "समूह हटाने में त्रुटि",
      selectMember: "कृपया कम से कम एक सदस्य चुनें!",
      checkinList: "चेक इन सूची लाने में त्रुटि",
      eventRequiredFields: "कृपया आवश्यक फ़ील्ड भरें: इवेंट प्रकार, मोड, शीर्षक और दिनांक समय",
      subEventRequiredFields:
        "कृपया उप-इवेंट में आवश्यक फ़ील्ड भरें: इवेंट प्रकार, मोड, शीर्षक और दिनांक समय",
      eventCapacity: "मूल ईवेंट क्षमता मान्य नहीं है, कृपया 0 से अधिक मान दर्ज करें",
      subEventCapacity: "उप इवेंट क्षमता मान्य नहीं है, कृपया 0 से अधिक मान दर्ज करें",
      eventList: "ईवेंट लाने में त्रुटि",
      invitationList: "आमंत्रण लाने में त्रुटि",
      invitationRequiredFields: "कृपया प्राप्तकर्ता ईमेल जोड़ें या कम से कम एक समूह चुनें!",
      invalidCredentials: "उपयोगकर्ता का नाम या संकेत - शब्द गलत है",
      eventTypeList: "ईवेंट प्रकार लाने में त्रुटि",
      memberTypeList: "सदस्य प्रकार लाने में त्रुटि",
      roleList: "भूमिकाएँ लाने में त्रुटि",
      userList: "उपयोगकर्ताओं को लाने में त्रुटि",
      userType: "उपयोगकर्ता प्रकार लाने में त्रुटि",
      plan: "योजनाएं लाने में त्रुटि",
      passwordIncorrect: "गलत पासवर्ड",
      rsvpList: "RSVP सूची लाने में त्रुटि",
      deleteRSVP: "RSVP रिकॉर्ड हटाने में त्रुटि",
      rsvpLessThanEvent: "आरएसवीपी की तारीख घटना की तारीख ({{date}}) से कम होनी चाहिए",
      firstReminderLessThanEvent: "पहली अनुस्मारक तिथि घटना तिथि ({{date}}) से कम होनी चाहिए",
      secondReminderGreaterThanFirstReminder:
        "दूसरे अनुस्मारक की तारीख पहली अनुस्मारक की तारीख से अधिक होनी चाहिए",
      secondReminderLessThanEvent:
        "दूसरे अनुस्मारक की तारीख घटना की तारीख से कम होनी चाहिए ({{date}})",
      customFormCheck: "कृपया कस्टम फॉर्म चेकबॉक्स जांचें",
    },
  },
  company: {
    companyList: "कंपनी सूची",
    createCompany: "कंपनी बनाएं",
    plan: "योजना",
    planEnd: "योजना समाप्ति",
    domain: "कार्यक्षेत्र",
    editCompany: "कंपनी संपादित करें",
    companyName: "कंपनी का नाम",
    companyEmail: "कंपनी ईमेल",
    companyPhone: "कंपनी का फ़ोन नंबर",
    industryType: "उद्योग के प्रकार",
    noOfEmployee: "कर्मचारी की संख्या",
    companyWebsite: "कंपनी वेबसाइट",
    companyAddress: "कम्पनी का पता",
    selectCountry: "देश चुनें",
    selectState: "राज्य चुनें",
    selectCity: "शहर चुनें",
    selectPlan: "प्लान का चयन करें",
    manageConfig: "कॉन्फ़िगरेशन प्रबंधित करें",
    eventCreateLimit: "इवेंट निर्माण सीमा",
    connectionLimit: "कनेक्शन सीमा",
    invitationSentLimit: "आमंत्रण भेजने की सीमा",
    userCreateLimit: "उपयोगकर्ता निर्माण सीमा",
    postEventCreateLimit: "पोस्ट इवेंट प्रपत्र निर्माण सीमा",
    domainSubscription: "डोमेन सदस्यता समाप्ति तिथि",
    publicEventRequestAllow: "अनाम अनुरोधों की अनुमति दें",
    customFormAllow: "कस्टम प्रपत्र निर्माण की अनुमति दें",
    agendaAllow: "एजेंडा निर्माण की अनुमति दें",
    faqAllow: "FAQ निर्माण की अनुमति दें",
    eventFeedbackFormAllow: "इवेंट फीडबैक फॉर्म निर्माण की अनुमति दें",
    passwordValidation: "पासवर्ड कम से कम 8+ होना चाहिए",
  },
  plan: {
    createPlan: "योजना बनाएं",
    editPlan: "योजना संपादित करें",
    planName: "योजना का नाम",
    planType: "योजना का प्रकार",
    planDetails: "योजना विवरण",
    monthly: "महीने के",
    quarterly: "त्रैमासिक",
    biannually: "वर्ष में दो बार",
    yearly: "सालाना",
    price: "कीमत",
    billing: "बिलिंग",
  },
  dashboard: {
    eventsByModes: "मोड के अनुसार घटनाएँ",
    invited: "आमंत्रित",
    requestedSocialMedia: "अनुरोध किया गया (सोशल मीडिया)",
    eventTypeStatistics: "घटना प्रकार सांख्यिकी",
    noOfAttendeeStatistics: "उपस्थित लोगों की संख्या सांख्यिकी",
    noOfTicketsPurchasedStatistics: "खरीदे गए टिकटों की संख्या सांख्यिकी",
    eventBaseTicketAndCheckIn: "घटनाओं के आधार पर टिकटों/चेक-इन की संख्या",
    eventBaseTicketAndCheckInInfo:
      "डिफ़ॉल्ट रूप से, उन सभी ईवेंट का पूर्वावलोकन किया जाता है जिनमें कोई उप ईवेंट नहीं होता है, उप ईवेंट की तुलना करने के लिए आंतरिक रूप से ईवेंट नाम का चयन करें",
    allEvents: "सभी कार्यक्रम",
    paid: "चुकाया गया",
  },
  organization: {
    myOrganization: "मेरा संगठन",
    companyDetails: "कंपनी का विवरण",
    details: "विवरण",
    plan: "योजना",
    help: "मदद",
    Label: {
      logo: "प्रतीक चिन्ह",
      name: "नाम",
      email: "ईमेल",
      contactNo: "संपर्क नंबर",
      address: "पता",
      domain: "कार्यक्षेत्र",
      country: "देश",
      state: "राज्य",
      city: "शहर",
      website: "वेबसाइट",
      noOfEmployee: "कर्मचारी की संख्या",
      industryType: "उद्योग के प्रकार",
      planId: "योजना क्रमांक",
      planName: "योजना का नाम",
      planStartDate: "योजना आरंभ तिथि",
      planEndtDate: "योजना समाप्ति तिथि",
      cancelPlan: "योजना रद्द करें",
      upgradePlan: "उन्नयन योजना",
      subject: "विषय",
      description: "विवरण",
      submit: "जमा करना",
      agendaAllow: "एजेंडा अनुमति",
      customFormAllow: "कस्टम फॉर्म की अनुमति दें",
      domainSubscription: "डोमेन सदस्यता",
      eventFeedbackFormAllow: "इवेंट फीडबैक फॉर्म की अनुमति दें",
      eventCreateLimit: "इवेंट निर्माण सीमा",
      faqAllow: "अक्सर पूछे जाने वाले प्रश्न अनुमति दें",
      publicEventRequestAllow: "सार्वजनिक कार्यक्रम अनुरोध अनुमति",
      invitationSentLimit: "निमंत्रण भेजने की सीमा",
      postEventCreateLimit: "पोस्ट इवेंट क्रिएट लिमिट",
      connectionLimit: "कनेक्शन सीमा",
      userCreateLimit: "उपयोगकर्ता सीमा बनाएं",
      current: "मौजूदा",
      configurations: "विन्यास",
    },
  },
}

export default {
  global: {
    welcome: "Bonjour, bon retour",
    admin: "Administrateur",
    dashboard: "Tableau de bord",
    events: "Événements",
    rsvp: "RSVP",
    connections: "Connexions",
    groups: "Groupes",
    invitation: "Invitation",
    eventOperations: "Opérations événementielles",
    master: "Maître",
    postEvent: "Après l'occassion",
    reports: "Rapports",
    attendees: "Participantes",
    tickets: "Des billets",
    checkin: "Enregistrement",
    users: "Utilisatrices",
    eventTypes: "Types d'événements",
    userTypes: "Types d'utilisateurs",
    roles: "Les rôles",
    memberType: "Types de membres",
    emailTemplate: "Modèle d'e-mail",
    coupons: "Coupons",
    company: "Entreprise",
    plans: "Des plans",
    home: "Maison",
    profile: "Profil",
    logout: "Se déconnecter",
    action: "Action",
    edit: "Modifier",
    add: "Ajouter",
    delete: "Supprimer",
    save: "Sauvegarder",
    update: "Mise à jour",
    view: "Voir",
    search: "Recherche",
    cancel: "Annuler",
    preview: "Aperçu",
    deleteMsg: "Etes-vous sûr de vouloir supprimer?",
    cancelMsg: "Êtes-vous sûr de vouloir annuler?",
    name: "Nom",
    firstName: "Prénom",
    lastName: "Nom de famille",
    email: "E-mail",
    phoneNumber: "Numéro de téléphone",
    phone: "Téléphone",
    status: "Statut",
    submit: "Soumettre",
    createdOn: "Créé sur",
    active: "Actif",
    inactive: "Inactif",
    yes: "Oui",
    no: "Non",
    title: "Titre",
  },
  event: {
    selectEventMode: "Sélectionnez le mode événement",
    offline: "Hors ligne",
    online: "En ligne",
    hybrid: "Hybride",
    upcoming: "A venir",
    ongoing: "En cours",
    completed: "Complété",
    createEvent: "Créer un évènement",
    noEventsFound: "Aucun événement trouvé",
    duplicate: "Dupliquer",
    addEvent: "Ajouter un évènement",
    selectEventType: "Sélectionnez le type d'événement",
    eventTitle: "Titre de l'événement",
    startDate: "Date de début",
    endDate: "Date de fin",
    eventDescription: "description de l'évenement",
    writeSomethingAwesome: "Écrivez quelque chose de génial...",
    eventInstructions: "Instructions pour l'événement",
    eventCapacity: "Capacité de l'événement",
    paid: "Payé?",
    eventPrice: "Prix ​​de l'événement",
    addLocation: "Ajouter un emplacement",
    uploadCoverImage: "Télécharger l'image de couverture",
    uploadFloorPlan: "Télécharger le plan d'étage",
    addSubEvent: "Ajouter un sous-événement",
    subEvents: "Sous-événements",
    location: "Emplacement",
    eventLocation: "Lieu de l'événement",
    locationRequiredMsg: "Veuillez entrer l'emplacement",
    multipleLocations: "Plusieurs emplacements",
    crewMembers: "Membres d'équipage",
    selectMemberType: "Sélectionnez le type de membre",
    addMember: "Ajouter un membre",
    updateOngoingEventMsg: "Vous ne pouvez pas modifier les événements en cours ou passés.",
    updateRSVPEventMsg:
      "Veuillez supprimer RSVP et autres données associées avant de modifier l'événement.",
    deleteOngoingEventMsg: "Vous ne pouvez pas supprimer des événements en cours ou passés.",
    deleteRSVPEventMsg:
      "Veuillez supprimer RSVP et autres données associées avant de supprimer l'événement.",
    editEvent: "Modifier l'événement",
    free: "Gratuit",
    about: "À propos",
    description: "Description",
    noDescriptionAdded: "Aucune description ajoutée",
    instructions: "Instructions",
    noInstructionsAdded: "Aucune instruction ajoutée",
    startDateTime: "Date/heure de début",
    endDateTime: "Date/heure de fin",
    eventMode: "Mode événement",
    selectEventTitle: "Sélectionnez le titre de l'événement",
    event: "Événement",
    importEvent: "Importer un événement",
    importRSVP: "Importer RSVP",
    importEventWithRSVP: "Créer un événement avec RSVP",
    singleEvent: "Seul événement",
    multipleEvents: "Plusieurs événements",
    viewEvent: "Voir l'événement",
    saveAsDraft: "Enregistrer comme brouillon",
    noLocations: "Aucun emplacement",
    noLink: "Pas de lien",
    noLinkProvidedbyContact: "Aucun lien fourni par le contact",
    addOns: "Modules complémentaires",
    eventFeatures: "Caractéristiques de l'événement",
    feedbackForm: "Formulaire de commentaires",
    feedbackFormDescription:
      "Créez des formulaires de commentaires personnalisés à remplir par les participants après l'événement",
    agenda: "Ordre du jour",
    agendaDescription: "Définir l'agenda de l'événement et les membres de leur équipe",
    faqs: "FAQ",
    faqDescription: "Créer une section FAQ pour les spectateurs d'événements",
    customRSVP: "RSVP personnalisé",
    customRSVPDescription: "Définir les formulaires RSVP pour l'événement",
    selectFromExisting: "Sélectionnez parmi les existants",
    createYourOwn: "Crée le tien",
    selectFeedbackForm: "Sélectionnez le formulaire de commentaires",
    selectAgenda: "Sélectionnez l'ordre du jour",
    selectFAQ: "Sélectionnez FAQ",
    selectRSVPForm: "Sélectionnez le formulaire RSVP",
    requestFeedback: "Demander des commentaires",
    userResponses: "Réponses des utilisateurs",
    noMembersAdded: "Aucun membre ajouté",
    viewingDraftEvent: "Vous consultez un brouillon d'événement.",
    reoccurringFromPreviousEvent: "Récurrent d'un événement précédent ?",
  },
  rsvp: {
    requiredFieldAtleastone: "Veuillez sélectionner au moins une option",
    requiredField: "champs requis",
    published: "Publié",
    draft: "Brouillon",
    cancelled: "Annulé",
    formDesign: "Conception de formulaire",
    details: "Détails",
    createdBy: "Créé par",
    copyRSVP: "Copier RSVP",
    addRSVP: "Ajouter RSVP",
    editRSVP: "Modifier RSVP",
    viewRSVP: "Voir RSVP",
    generalSettings: "réglages généraux",
    designForm: "Formulaire de conception",
    configureReminders: "Configurer les rappels",
    viewPublish: "Afficher et publier",
    rsvpByDate: "RSVP par date",
    rsvpByTime: "RSVP par heure",
    allowGuestsToDo: "Autoriser les invités à faire",
    individualRSVP: "RSVP individuel",
    setLimitForPlusOnes: "Fixer une limite pour les Plus-1",
    mentionPlusOne: "L'invité principal doit-il mentionner le nom des plus-un ?",
    emailAddressSupport: "Adresse e-mail (assistance)",
    contactNoSupport: "Numéro de contact (assistance)",
    firstReminderMsg:
      "Définissez le premier e-mail de rappel pour que le participant principal soumette les noms de ses plus-uns",
    secondReminderMsg:
      "Définissez les données de l'avant-dernier e-mail de rappel pour que le participant principal puisse soumettre les noms de ses plus-uns.",
    firstReminderInfo:
      "Cet e-mail sera envoyé comme <br /> premier rappel au <br />participant principal pour qu'il soumette le nom <br /> de ses plus-uns.",
    firstReminderDate: "Date du premier rappel pour les informations sur le participant principal",
    secondReminderDate:
      "Deuxième date de rappel pour les informations sur le participant principal",
    prev: "Précédent",
    next: "Suivant",
    rsvpFormDesign: "Conception du formulaire RSVP",
    basic: "Basique",
    custom: "Coutume",
    basicFormFields: "Champs de formulaire de base",
    customFormFields: "Champs de formulaire personnalisés",
    selectCustomTemplate: "Sélectionnez un modèle de formulaire personnalisé",
    selectTemplate: "Sélectionnez un modèle",
    pleaseLetUsKnow: "S'il vous plaît laissez-nous savoir si vous pourrez y venir.",
    yesIllBeThere: "Oui, je serai là",
    noIcantMakeIt: "Non, je ne peux pas y arriver",
    useBasicFormMsg: "Je souhaite utiliser le formulaire de base pour mon événement",
    useForm: "Utiliser le formulaire",
    typeAQuestion: "Tapez une question",
    selectFieldType: "Sélectionnez le type de champ",
    phone: "Téléphone",
    textbox: "Zone de texte",
    textarea: "Zone de texte",
    checkbox: "Case à cocher",
    dropdown: "Dérouler",
    radioButton: "Bouton radio",
    addFormElement: "Ajouter un élément de formulaire",
    useCustomFormMsg: "Je souhaite utiliser un formulaire personnalisé pour mon événement",
    designEmailTemplates: "Conception de modèles d'e-mails par défaut ou récurrents",
    default: "Défaut",
    reminder: "Rappel",
    messageWhen: "Message quand",
    rsvpIsYes: "RSVP est Oui",
    rsvpIsNo: "RSVP est Non",
    reminderDate1st: "La 1ère date de rappel est proche pour les détails des participants",
    reminderDate2nd: "La 2ème date de rappel est proche pour les détails des participants",
    registrationClosed: "Les inscriptions doivent être clôturées",
    eventDateApproached: "La date de l'événement est proche",
    subject: "Sujet",
    typeAMessage: "Tapez un message",
    attachPaymentReceipt: "Joindre le reçu de paiement",
    sendLinkToNamePlusOnes: "Envoyer un lien pour nommer les plus-uns",
    previewToRsvp: "Un aperçu du RSVP",
    contactInfo: "Informations de contact",
    reservation: "Réservation",
    publishRSVPPage: "Publier la page RSVP",
    rsvpLinkCopied: "Votre lien RSVP a été copié.",
    thankYou: "Merci!",
    alreadySubmitted: "Vous avez déjà soumis le formulaire RSVP.",
    reachUsForAssistance: "Vous pouvez nous joindre pour toute assistance :",
    formSubmitted: "Votre formulaire RSVP a été soumis avec succès.",
    eventAtFullCapacity: "Cet événement est à pleine capacité.",
    thankYouForYourInterest: "Merci pour ton intérêt!",
    rsPerPerson: "RS/Personne",
    total: "Total:",
    payWithCard: "Payer avec carte",
    payNow: "Payez maintenant",
    reasonToRefuse: "Raison de refuser",
    additionalComments: "Commentaires supplémentaires / Mentionnez votre raison",
    dateNotSuitable: "Date non adaptée",
    timeNotSuitable: "Heure non adaptée",
    priceTooHigh: "Le prix de l'événement est trop élevé",
    someOtherPlan: "J'ai d'autres projets",
    notInterested: "Pas intéressé",
    reasonNotMentioned: "Mes raisons ne sont pas mentionnées ici",
    rsvpSubmittedSuccessfully: "RSVP Soumis avec succès !",
    errorSubmittingRSVP: "Erreur lors de la soumission de RSVP !",
    unauthorizedAccess: "Accès non autorisé. Veuillez réessayer.",
    rsvpGuestFormSubmitted: "Votre formulaire d'invité RSVP a été soumis avec succès.",
    guestInfoSubmitted: "Informations sur les invités Soumis avec succès !",
    errorSubmittingGuestInfo: "Erreur lors de la soumission des informations sur les invités !",
    primaryAttendeeName: "Nom du participant principal",
    eventName: "Nom de l'événement",
    youCanNotAddGuest: "Vous ne pouvez pas ajouter d'invités pour cet événement",
    noGuestsAreAdded: "Aucun invité n'est ajouté",
    addGuest: "Ajouter un invité",
    yes: "Oui",
    no: "Non",
    rsvpBy: "RSVP par",
    fieldOptions: "Options de champ",
    addCommaSeperatedOptions: "Ajouter des options séparées par des virgules",
    basicPlusCustomFormtext:
      "Les champs du formulaire de base + personnalisé seront visibles sur le formulaire RSVP",
  },
  connection: {
    newConnection: "Nouvelle connexion",
    upload: "Télécharger",
    remove: "Retirer",
    website: "Site web",
    socialMediaLinks: "Liens vers les réseaux sociaux",
    addSocialMediaLink: "Ajouter un lien vers les réseaux sociaux",
    editConnection: "Modifier la connexion",
    groupName: "Nom de groupe",
    members: "Membres",
    viewMember: "Afficher le membre",
    newGroup: "Nouveau groupe",
    editGroup: "Modifier le groupe",
    groupMembers: "Les membres du groupe",
    or: "OU",
    groupDeleteNote: "Remarque : Cela ne supprimera aucun membre.",
  },
  invitation: {
    sent: "Envoyé",
    request: "Demande",
    date: "Date",
    sendInvite: "Envoyer une invitation",
    to: "À",
    chooseGroup: "Choisissez un groupe",
    eventTitleInfo:
      "Le titre de l'événement sera affiché pour les événements pour lesquels des paramètres RSVP et Ticket publiés existent.",
    send: "Envoyer",
    viewInvite: "Afficher l'invitation",
    requested: "Demandé",
  },
  attendees: {
    totalAmtPaid: "Montant total payé",
    maxLimit: "Limite maximale",
    accepted: "Accepté",
    rejected: "Rejeté",
    remaining: "Restant",
    closingDate: "Date de clôture",
    viewAttendee: "Afficher le participant",
    payment: "Paiement",
    plusOnes: "Les Plus-Un",
    history: "Histoire",
    personalInformation: "Informations personnelles",
    eventsAttending: "Participation à des événements",
    paymentReceipt: "Reçu",
    viewAsPDF: "Afficher au format PDF",
    paymentInformation: "Informations de paiement",
    receiptNo: "Numéro de reçu",
    transactionID: "identifiant de transaction",
    transactionDetails: "détails de la transaction",
    paymentMethod: "Mode de paiement",
    tax: "Impôt",
    dateReceived: "Date de réception",
    subTotal: "Total",
    totalPaymentReceived: "Paiement total reçu",
    noPaymentReceived: "Aucun paiement reçu",
    detailsOfPlusOnes: "Détails des Plus-un",
    noUserAttendeesAvailable: "Aucun participant utilisateur disponible",
    historyEmailTransactions: "Historique - Transactions par courrier électronique",
    noEmailLogsAvailable: "Aucun journal de courrier électronique disponible",
  },
  ticket: {
    whatYourTicketShouldConsist: "En quoi doivent consister vos billets ?",
    enableAll: "Activer tout",
    qrCodeId: "Code QR et identifiant",
    regularVIP: "Régulier/VIP",
    primaryAttendeeNamePlusOne: "Nom du participant principal (nombre de plus un)",
    separateTicketforPlusOne: "Ticket séparé pour les noms principaux et plus",
    mainEventTitle: "Titre de l'événement principal",
    individualEventTitle: "Titre de l'événement individuel",
    venue: "Lieu",
    eventDateTime: "Date et heure de l'événement",
    seats: "Des places",
    notes: "Remarques",
    termsConditions: "termes et conditions",
    attendeeName: "Nom du participant",
  },
  checkIn: {
    checkInTime: "Heure d'arrivée",
  },
  user: {
    usertype: "Type d'utilisateur",
    role: "Rôle",
    password: "Mot de passe",
    newUser: "Nouvel utilisateur",
    editUser: "Modifier l'utilisateur",
    selectUserType: "Sélectionnez le type d'utilisateur",
    selectRole: "Sélectionnez un rôle",
  },
  eventType: {
    eventtype: "Type d'événement",
    newEventType: "Nouveau type d'événement",
    editEventType: "Modifier le type d'événement",
  },
  userType: {
    newUserType: "Nouveau type d'utilisateur",
    editUserType: "Modifier le type d'utilisateur",
  },
  coupon: {
    couponSavings: "coupons d'épargne",
    toPay: "Payer",
    applied: "Appliqué!",
    discount: "Rabais",
    couponCriteriaNotMatch: "Les critères du coupon ne correspondent pas !",
    invalidCoupon: "Code promo invalide ! Veuillez essayer avec un code différent.",
    selectCoupons: "Sélectionnez les coupons",
    typeCouponCodeHere: "Tapez le code promo ici",
    apply: "Appliquer",
    viewCoupons: "Voir les coupons",
    copyCode: "Copier le code",
    copiedCouponCode: "Code promo copié",
    viewDetails: "Afficher les détails",
    paymentCouponForYou: "Des bons de paiement pour vous",
    addCoupon: "Ajouter un coupon",
    newCoupon: "Nouveau coupon",
    editCoupon: "Modifier le coupon",
    validTill: "Valable jusqu'au",
    fixedDiscount: "Remise fixe",
    percentageDiscount: "Pourcentage de réduction",
    noOfTickets: "Nombre de billets",
    couponName: "Nom du coupon",
    couponCode: "Code promo",
    criteria: "Critères",
    field: "Champ",
    type: "Taper",
    number: "Nombre",
    condition: "Condition",
    addMoreCriteria: "Ajouter plus de critères",
  },
  role: {
    roleName: "Nom de rôle",
    newRole: "Nouveau rôle",
    editRole: "Modifier le rôle",
    standard: "Standard",
    effectedModules: "Modules concernés",
    userroles: "Rôles des utilisateurs",
    emailtemplate: "Modèle d'e-mail",
    invitations: "Invitations",
  },
  memberType: {
    newMemberType: "Nouveau type de membre",
    editMemberType: "Modifier le type de membre",
  },
  emailTemplate: {
    id: "IDENTIFIANT",
    keywords: "Mots clés",
    uniqueIdentifier: "Identifiant unique",
    editEmailTemplate: "Modifier le modèle d'e-mail",
  },
  postEvent: {
    feedback: "Retour",
    faq: "FAQ",
    agenda: "Ordre du jour",
    customForm: "Formulaire personnalisé",
    newForm: "Nouvelle forme",
    questions: "Des questions",
    question: "Question",
    numberOfStar: "Nombre d'étoiles",
    formTitle: "Titre du formulaire",
    addMoreQuestion: "Ajouter plus de questions",
    forms: "Formes",
    formType: "Type de formulaire",
    feedbackFrom: "Formulaire de commentaires",
    agendaForm: "Formulaire d'ordre du jour",
    faqForm: "Formulaire FAQ",
    customRsvpForm: "Formulaire RSVP personnalisé",
    title: "Titre",
    startTime: "Heure de début",
    endTime: "Heure de fin",
    hostArtistName: "Nom de l'hôte/de l'artiste",
    description: "Description",
    addMoreHostArtistName: "Ajoutez une valeur séparée par des virgules pour en ajouter plus",
    answer: "Répondre",
    agendaSubtitle:
      "Ajoutez un itinéraire, un calendrier ou une programmation à votre événement. Vous pouvez inclure une heure, une description de ce qui va se passer et qui animera ou se produira pendant l'événement. (Ex. conférencier, interprète, artiste, guide, etc.) Si votre événement a plusieurs dates, vous pouvez ajouter un deuxième agenda.",
    faqSubtitle:
      "Répondez aux questions que vos participants peuvent avoir sur l'événement, comme le stationnement, l'accessibilité et les remboursements.",
    addMore: "Ajouter plus",
  },
  reports: {
    selectModule: "Sélectionner un module",
    selectFields: "Sélectionnez les champs",
    generate: "Générer",
  },
  profile: {
    account: "Compte",
    general: "Général",
    security: "Sécurité",
    gender: "Genre",
    male: "Mâle",
    female: "Femelle",
    address: "Adresse",
    oldPassword: "ancien mot de passe",
    newPassword: "nouveau mot de passe",
    confirmNewPassword: "Confirmer le nouveau mot de passe",
    updatePassword: "Mettre à jour le mot de passe",
  },
  emptyState: {
    noOptions: "Aucune option",
    noRowsFound: "Aucune ligne trouvée",
    noDataAvailableSelectEvent:
      "Aucune donnée disponible, veuillez d'abord sélectionner l'événement.",
    noDataAvailableSelectField:
      "Aucune donnée disponible, veuillez d'abord sélectionner les champs.",
    noTicketsGenerated: "Aucun billet n'est généré pour cet événement.",
  },
  upload: {
    uploadPhoto: "Envoyer la photo",
    filesAllowed: "Autorisé *.jpeg, *.jpg, *.png, *.gif",
    allowedCSV: "Fichier *.csv autorisé uniquement",
    downloadCSV: "Téléchargez un exemple de format CSV",
    maxSize: "taille maximale de {{size}}",
    dropOrSelectFile: "Déposer ou sélectionner un fichier",
    dropFilesHereOrBrowse:
      "Déposez les fichiers ici ou cliquez sur <1> Parcourir </1> sur votre ordinateur",
  },
  toast: {
    success: {
      login: "Utilisateur connecté avec succès",
      logout: "L'utilisateur s'est déconnecté avec succès.",
      loginAgain: "Veuillez vous reconnecter !",
      inactiveLogout: "Déconnecté pour cause d'inactivité",
      deleteCompany: "Enregistrement de l'entreprise supprimé avec succès !",
      deleteConnection: "Connexion supprimée avec succès !",
      deleteGroup: "Groupe supprimé avec succès !",
      updateGroup: "Groupe mis à jour avec succès !",
      resetPasswordLink: "Vous recevrez sous peu un lien de réinitialisation du mot de passe !",
      deleteInvitation: "Enregistrement d'invitation supprimé avec succès !",
      deletePlan: "Plan supprimé avec succès !",
    },
    error: {
      postEventList: "Erreur lors de la récupération de la liste des événements post-événement",
      action: "Échec de l'opération !",
      companyList: "Erreur lors de la récupération de la liste des entreprises",
      connectionList: "Erreur lors de la récupération des connexions",
      deleteConnection: "Erreur lors de la suppression de la connexion",
      groupList: "Erreur lors de la récupération des groupes",
      deleteGroup: "Erreur lors de la suppression du groupe",
      selectMember: "Veuillez sélectionner au moins un membre !",
      checkinList: "Erreur lors de la récupération de la liste d'enregistrement",
      eventRequiredFields:
        "Veuillez remplir les champs obligatoires : type d'événement, mode, titre et date heure",
      subEventRequiredFields:
        "Veuillez remplir les champs obligatoires dans les sous-événements : type d'événement, mode, titre et date/heure.",
      eventCapacity:
        "La capacité de l'événement parent n'est pas valide, veuillez saisir une valeur supérieure à 0",
      subEventCapacity:
        "La capacité du sous-événement n'est pas valide, veuillez saisir une valeur supérieure à 0",
      eventList: "Erreur lors de la récupération des événements",
      invitationList: "Erreur lors de la récupération des invitations",
      invitationRequiredFields:
        "Veuillez ajouter l'adresse e-mail du destinataire ou sélectionner au moins un groupe !",
      invalidCredentials: "Nom d'utilisateur ou mot de passe incorrect",
      eventTypeList: "Erreur lors de la récupération des types d'événements",
      memberTypeList: "Erreur lors de la récupération du type de membre",
      roleList: "Erreur lors de la récupération des rôles",
      userList: "Erreur lors de la récupération des utilisateurs",
      userType: "Erreur lors de la récupération des types d'utilisateurs",
      plan: "Erreur lors de la récupération des plans",
      passwordIncorrect: "Mot de passe incorrect",
      rsvpList: "Erreur lors de la récupération de la liste RSVP",
      deleteRSVP: "Erreur lors de la suppression de l'enregistrement RSVP",
      rsvpLessThanEvent: "La date de RSVP doit être inférieure à la date de l'événement ({{date}})",
      firstReminderLessThanEvent:
        "La première date de rappel doit être inférieure à la date de l'événement ({{date}})",
      secondReminderGreaterThanFirstReminder:
        "La 2ème date de rappel doit être supérieure à la 1ère date de rappel",
      secondReminderLessThanEvent:
        "La deuxième date de rappel doit être inférieure à la date de l'événement ({{date}})",
      customFormCheck: "Veuillez cocher la case du formulaire personnalisé",
    },
  },
  company: {
    companyList: "Liste des entreprises",
    createCompany: "Créer une entreprise",
    plan: "Plan",
    planEnd: "Fin du plan",
    domain: "Domaine",
    editCompany: "Modifier la société",
    companyName: "Nom de l'entreprise",
    companyEmail: "E-mail de l'entreprise",
    companyPhone: "Numéro de téléphone de l'entreprise",
    industryType: "type d'industrie",
    noOfEmployee: "Nombre d'employés",
    companyWebsite: "site Web d'entreprise",
    companyAddress: "Adresse de la société",
    selectCountry: "Choisissez le pays",
    selectState: "Sélectionnez l'état",
    selectCity: "Sélectionnez une ville",
    selectPlan: "Sélectionnez le plan",
    manageConfig: "Gérer la configuration",
    eventCreateLimit: "Limite de création d'événements",
    connectionLimit: "Limite de connexion",
    invitationSentLimit: "Envoyer une invitation Limite",
    userCreateLimit: "Limite de création d'utilisateurs",
    postEventCreateLimit: "Limite de création du formulaire post-événement",
    domainSubscription: "Date de fin de l'abonnement au domaine",
    publicEventRequestAllow: "Autoriser les demandes anonymes",
    customFormAllow: "Autoriser la création de formulaires personnalisés",
    agendaAllow: "Autoriser la création d'agenda",
    faqAllow: "Autoriser la création de FAQ",
    eventFeedbackFormAllow:
      "Autoriser la création de formulaires de commentaires sur les événements",
    passwordValidation: "Le mot de passe doit être au minimum 8+",
  },
  plan: {
    createPlan: "Créer un plan",
    editPlan: "Modifier le forfait",
    planName: "Nom du régime",
    planType: "Type de régime",
    planDetails: "Détails du forfait",
    monthly: "Mensuel",
    quarterly: "Trimestriel",
    biannually: "Tous les deux ans",
    yearly: "Annuel",
    price: "Prix",
    billing: "Facturation",
  },
  dashboard: {
    eventsByModes: "Événements par modes",
    invited: "Invité",
    requestedSocialMedia: "Demandé (réseaux sociaux)",
    eventTypeStatistics: "Type d'événement Statistiques",
    noOfAttendeeStatistics: "Nombre de participants Statistiques",
    noOfTicketsPurchasedStatistics: "Nombre de billets achetés Statistiques",
    eventBaseTicketAndCheckIn: "Nombre de billets/enregistrement en fonction des événements",
    eventBaseTicketAndCheckInInfo:
      "Par défaut, tous les événements qui n'ont pas de sous-événement sont prévisualisés. Pour comparer les sous-événements en interne, sélectionnez le nom de l'événement.",
    allEvents: "Tous les évènements",
    paid: "Payé",
  },
  organization: {
    myOrganization: "Mon organisation",
    companyDetails: "Détails de l'entreprise",
    details: "Détails",
    plan: "Plan",
    help: "Aide",
    Label: {
      logo: "Logo",
      name: "Nom",
      email: "E-mail",
      contactNo: "Numéro de contact",
      address: "Adresse",
      domain: "Domaine",
      country: "Pays",
      state: "État",
      city: "Ville",
      website: "Site web",
      noOfEmployee: "Nombre d'employés",
      industryType: "type d'industrie",
      planId: "Identifiant du forfait",
      planName: "Nom du régime",
      planStartDate: "Date de début du forfait",
      planEndtDate: "Date de fin du plan",
      cancelPlan: "Annuler le forfait",
      upgradePlan: "Plan de mise à niveau",
      subject: "Sujette",
      description: "Description",
      submit: "Soumettre",
      agendaAllow: "Autoriser l'agenda",
      customFormAllow: "Formulaire personnalisé Autoriser",
      domainSubscription: "Abonnement au domaine",
      eventFeedbackFormAllow: "Formulaire de commentaires sur l'événement Autoriser",
      eventCreateLimit: "Limite de création d'événement",
      faqAllow: "FAQ Autoriser",
      publicEventRequestAllow: "Demande d'événement public Autoriser",
      invitationSentLimit: "Limite des invitations envoyées",
      postEventCreateLimit: "Limite de création post-événement",
      connectionLimit: "Limite de connexion",
      userCreateLimit: "Limite de création d'utilisateur",
      current: "Actuelle",
      configurations: "Configurations",
    },
  },
}
